import React, { useState, useEffect, useRef } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { Button, Theme } from '@mui/material';
import NoData from 'components/nodata/NoData';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import PaginationProvider from 'providers/pagination';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'providers/app';
import useSearch from 'hooks/useSearch';
import useTableOrder from 'hooks/useTableOrder';
import { usersTableTemplate } from './usersTableTemplate';
import RestaurantTableList from './list/table/UsersTableList';
import RestaurantModuleList from './list/module/UserModuleList';
import history from 'services/history';
import { User } from 'types/user';
import UsersActions from './UsersActions';
import { makeStyles } from '@mui/styles';
import UsersFilterBox from './UsersFilterBox';
import TableContainer from 'components/table/TableContainer';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const search = useSearch();
  const [filtered, setFiltered] = useState<User[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [indexToSearch, setIndexToSearch] = useState('name');

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get<User[]>('/users', { cancelToken: source.token })
      .then(response => {
        if (request) {
          const _users = response.data.map(user => {
            user.restaurantName = user.restaurant && user.restaurant.name;
            user.formattedActivated = user.active ? 'Sim' : 'Não';
            return user;
          });
          setUsers(_users);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    setFiltered(users.slice());
  }, [users]);

  useEffect(() => {
    const p = search(searchValue, indexToSearch, users);
    setFiltered(p);
  }, [indexToSearch, searchValue, search, users]);

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [indexToSearch]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <>
      <Appbar title="Usuários" ActionsComponent={<UsersActions />} />

      <PageHeaderActions
        title="Usuários"
        description="Gestão dos usuários"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/user')}>
            Adicionar
          </Button>
        }
      />

      <TableContainer tableTemplate={usersTableTemplate}>
        <UsersFilterBox
          displayMode={displayMode}
          indexToSearch={indexToSearch}
          searchValue={searchValue}
          setDisplayMode={setDisplayMode}
          setIndexToSearch={setIndexToSearch}
          setSearchValue={setSearchValue}
        />
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum usuário para exibir" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <RestaurantTableList users={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <RestaurantModuleList users={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </>
  );
};

export default Users;
