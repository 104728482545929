import { Dispatch, SetStateAction, useState } from 'react';
import { Restaurant } from 'types/restaurant';
import * as yup from 'yup';

export interface RestaurantValidation {
  corporate_name?: string;
  image?: string;
  secondary_color?: string;
  primary_color?: string;
  keywords?: string;
  title?: string;
  description?: string;
  email?: string;
  url?: string;
  minimum_order?: string;
  working_hours?: string;
  name?: string;
  cnpj?: string;
  alias?: string;
  play_store_link?: string;
}

type UseRestaurantValidation = [
  RestaurantValidation,
  Dispatch<SetStateAction<RestaurantValidation>>,
  (restaurant: Restaurant) => Promise<void>,
];

export function useRestaurantValidation(): UseRestaurantValidation {
  const [validation, setValidation] = useState<RestaurantValidation>({} as RestaurantValidation);

  async function handleValidation(restaurant: Restaurant) {
    const schema = yup.object().shape({
      phones: yup.array().min(1, 'Você precisa informar um telefone').required('O telefone é obrigatório'),
      addresses: yup.array().min(1, 'Você precisa informar um endereço').required('O endereço é obrigatório'),
      image: yup
        .object()
        .required('A logo do restaurante é obrigatória')
        .typeError('A logo do restaurante é obrigatória'),
      secondary_color: yup.string().required('A cor secundária é obrigatória'),
      primary_color: yup.string().required('A cor primária é obrigatória'),
      keywords: yup.string(),
      title: yup.string().required('Título da página inicial é obrigátório'),
      play_store_link: yup.string().url('Informe uma URL válida').nullable(),
      alias: yup.string().required('O alias é obrigatório'),
      description: yup.string().required('A descrição do delivery é obrigatória'),
      email: yup.string().email('Você deve informar um e-mail válido').required('O e-mail é obrigatório'),
      url: yup.string().url('Você deve informar uma URL válida').required('A URL é obrigatória'),
      minimum_order: yup.number().typeError('Você deve informar um número válido').nullable(true),
      working_hours: yup.string().required('O horário de atendimento é obrigatório'),
      name: yup.string().required('O nome do restaurante é obrigatório'),
      cnpj: yup.string(),
      corporate_name: yup.string().required('A razão social é obrigatória'),
    });

    try {
      await schema.validate(restaurant);
    } catch (err) {
      const error = err as yup.ValidationError;

      setValidation({
        [error.path as string]: error.message,
      });
      throw err;
    }
  }

  return [validation, setValidation, handleValidation];
}
