import React, { FormEvent, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import { useMessaging } from 'providers/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import PlanUpdateActions from './PlanUpdateActions';
import PlanForm from '../PlanForm';
import PageHeader from 'components/page-header/PageHeader';
import { usePlanValidation } from '../validation/planValidation';
import { Plan } from 'types/plan';
import PLanTabs from '../PlanTabs';
import { Resource } from 'types/resource';
import PlanResources from '../PlanResources';

export type AccessRuleValidation = {
  name?: string;
  title?: string;
  description?: string;
};

const PlanUpdate: React.FC = () => {
  const [plan, setPlan] = useState<Plan>({} as Plan);
  const [validation, setValidation, validate] = usePlanValidation();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const { id } = useParams<{ id: string }>();
  const [resources, setResources] = useState<Resource[]>([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (!id) return;

    async function loadPlan() {
      const _plan = await api.get<Plan>(`/plans/${id}`);
      setPlan(_plan.data);

      const resourceParents = await api.get<Resource[]>('/resource-parents');

      function updateChildren(resources: Resource[]) {
        return resources.map(resource => {
          resource.enabled = !!_plan.data.resources.find(r => r.name === resource.name);
          resource.resources = updateChildren(resource.resources);
          return resource;
        });
      }

      setResources(
        resourceParents.data.map(rp => {
          rp.enabled = !!_plan.data.resources.find(r => r.name === rp.name);
          rp.resources = updateChildren(rp.resources);
          return rp;
        }),
      );
    }

    loadPlan()
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível carregar o perfil');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, handleOpen]);

  function handleChange(index: keyof Plan, value: any) {
    setPlan(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(plan)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path as string]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/plans/${id}`, { ...plan, resources })
      .then(() => {
        handleOpen('Salvo');
        setLoading(false);
        history.push('/plans');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o plano');
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/plans/${id}`)
      .then(() => {
        setLoading(false);
        history.push('/plans');
        handleOpen('Recurso excluído');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível excluir o plano');
      });
  }

  function handleTabChange(value) {
    setTab(value);
  }

  function handleResourceChange(id: number) {
    const updateChildren = (resources: Resource[], state: boolean) =>
      resources.map(resource => {
        resource.enabled = state;
        resource.resources = updateChildren(resource.resources, state);
        return resource;
      });

    const handleSetValue = (resources: Resource[], id: number) =>
      resources.map(resource => {
        if (resource.id === id) {
          resource.enabled = !resource.enabled;
          resource.resources = updateChildren(resource.resources, !!resource.enabled);
        }
        handleSetValue(resource.resources, id);
        return resource;
      });

    const newValue = handleSetValue(resources, id);
    setResources(JSON.parse(JSON.stringify(newValue)));
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        backAction={() => history.push('/resources')}
        title="Recurso"
        ActionsComponent={<PlanUpdateActions handleSubmit={handleValidation} handleDelete={handleDelete} />}
        Tab={<PLanTabs tab={tab} handleTabChange={value => handleTabChange(value)} />}
      />
      <PageHeader title="Editar plano" />
      {loading ? (
        <InsideLoading />
      ) : tab === 0 ? (
        <Grid container direction="column">
          <Grid item xs={12} lg={4} xl={4} md={6}>
            <form onSubmit={handleValidation}>
              <PlanForm handleChange={handleChange} plan={plan} validation={validation} />
              <button type="submit" style={{ display: 'none' }} />
            </form>
          </Grid>
        </Grid>
      ) : (
        <PlanResources resources={resources} handleResourceChange={handleResourceChange} />
      )}
    </>
  );
};

export default PlanUpdate;
