import React, { useEffect, useRef } from 'react';
import { FormControlLabel, Switch, TextField } from '@mui/material';
import { PlanValidation } from './validation/planValidation';
import { Plan } from 'types/plan';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  switch: {
    marginTop: 15,
  },
  container: {
    maxWidth: 400,
  },
});

type PlanFormProps = {
  plan: Plan;
  handleChange(index: string, value: any): void;
  validation: PlanValidation;
};

const PlanForm: React.FC<PlanFormProps> = ({ plan, handleChange, validation }) => {
  const classes = styles();
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    price: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <div className={classes.container}>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome do plano"
        placeholder="Digite o nome do plano"
        margin="normal"
        autoFocus
        fullWidth
        value={plan.name || ''}
        onChange={e => handleChange('name', e.target.value)}
      />
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Description"
        placeholder="Digite a descrição do plano"
        margin="normal"
        fullWidth
        value={plan.description || ''}
        onChange={e => handleChange('description', e.target.value)}
        autoComplete="none"
      />
      <TextField
        inputRef={inputs.price}
        error={!!validation.price}
        helperText={validation.price}
        label="Preço (R$)"
        placeholder="Digite a preço do plano"
        margin="normal"
        fullWidth
        value={plan.price || ''}
        onChange={e => handleChange('price', e.target.value)}
        type="number"
        inputProps={{ step: 0.01 }}
      />
      <FormControlLabel
        label="Visível para usuários do admin.sgrande.delivery"
        className={classes.switch}
        control={
          <Switch
            color="primary"
            checked={plan.is_visible}
            onChange={e => handleChange('is_visible', e.target.checked)}
          />
        }
      />
    </div>
  );
};

export default PlanForm;
