import React, { FormEvent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Done } from '@mui/icons-material';
import { useDialog } from 'components/dialog/Dialog';

type RestaurantAddressUpdateActionsProps = {
  handleSubmit(handleClose: () => void, e?: FormEvent<HTMLFormElement>): void;
};

const RestaurantAddressUpdateActions: React.FC<RestaurantAddressUpdateActionsProps> = ({ handleSubmit }) => {
  const { handleClose } = useDialog();

  function handleClick() {
    handleSubmit(handleClose);
  }

  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleClick}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default RestaurantAddressUpdateActions;
