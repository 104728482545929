import { Dispatch, SetStateAction, useState } from 'react';
import { Resource } from 'types/resource';
import * as yup from 'yup';

export type ResourceValidation = {
  name?: string;
  title?: string;
  description?: string;
};

type UseResourceValidation = [
  ResourceValidation,
  Dispatch<SetStateAction<ResourceValidation>>,
  (accessRule: Resource) => Promise<void>,
];

export function useResourceValidation(): UseResourceValidation {
  async function handleValidation(resource: ResourceValidation) {
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      title: yup.string().required('O título é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
    });

    try {
      await schema.validate(resource);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }
      throw err;
    }
  }

  const [validation, setValidation] = useState<ResourceValidation>({});
  return [validation, setValidation, handleValidation];
}
