export const INITIAL_STATE = {
  name: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  restaurant_id: '',
  rule: 'admin-admin',
};

export default function User(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_USER': {
      return {
        ...action.user,
        restaurant_id: action.user.restaurant_id ? action.user.restaurant_id : '',
      };
    }

    default: {
      return state;
    }
  }
}
