import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useRestaurants } from '../hook/useRestaurants';

type RestaurantListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RestaurantListMenu: React.FC<RestaurantListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedRestaurant, handleActivateRestaurant, handleDeactivateRestaurant } = useRestaurants();
  function handleCopyURLAddress() {
    if (!selectedRestaurant) return;

    navigator.clipboard.writeText(selectedRestaurant?.url).catch(err => console.error(err));

    setAnchorEl(null);
  }

  function handleOpen() {
    window.open(selectedRestaurant?.url);
    setAnchorEl(null);
  }

  function handleDeativateClick() {
    handleDeactivateRestaurant();
    setAnchorEl(null);
  }

  function handleAtivateClick() {
    handleActivateRestaurant();
    setAnchorEl(null);
  }

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleCopyURLAddress}>Copiar URL</MenuItem>
      <MenuItem onClick={handleOpen}>Abrir loja</MenuItem>
      <MenuItem onClick={selectedRestaurant?.active ? handleDeativateClick : handleAtivateClick}>
        {selectedRestaurant?.active ? 'Desativar' : 'Ativar'}
      </MenuItem>
    </Menu>
  );
};

export default RestaurantListMenu;
