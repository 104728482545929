import React, { useEffect, useState } from 'react';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableContainer from 'components/table/TableContainer';
import { endOfMonth, startOfMonth } from 'date-fns';
import { invoicesTableTemplate } from './invoicesTableTemplate';
import InvoiceListModule from './list/module/InvoiceListModule';
import InvoiceListTable from './list/table/InvoiceListTable';
import NoData from 'components/nodata/NoData';
import { styled } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'providers/pagination';
import useTableOrder from 'hooks/useTableOrder';
import { useApp } from 'providers/app';
import { Invoice } from 'types/invoice';
import { useFetchInvoices } from './hooks/useFetchInvoices';
import { InvoicesProvider } from './hooks/useInvoices';
import ApiPagination from 'components/pagination/ApiPagination';
import InvoicesFilterBox from './InvoicesFilterBox';
import { api } from 'services/api';
import history from 'services/history';
import MobileSearch from './InvoicesSearchDialog';
import InvoicesActions from './InvoicesActions';

const Container = styled('div')(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface InvoicesParams {
  rows: number;
  page: number;
  term: string;
  initial_date: Date;
  final_date: Date;
  only_paid: boolean;
}

const initialParamsValue: InvoicesParams = {
  rows: 20,
  page: 1,
  term: '',
  initial_date: startOfMonth(new Date()),
  final_date: endOfMonth(new Date()),
  only_paid: false,
};

const Invoices: React.FC = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<null | Invoice>(null);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Invoice[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [params, setParams] = useState<InvoicesParams>(initialParamsValue);
  const { invoices, loading, total, setInvoices } = useFetchInvoices(params);
  const app = useApp();
  const [orderedIndex, sort] = useTableOrder();
  const [saving, setSaving] = useState(false);
  const [isOpenedSearchDialog, setIsOpenedSearchDialog] = useState(false);

  useEffect(() => {
    setFiltered(invoices);
  }, [invoices]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleParamsChange(index: keyof InvoicesParams, value: any) {
    setParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleUpdateList(invoice: Invoice) {
    setInvoices(state => state.map(item => (item.id === invoice.id ? invoice : item)));
  }

  function handleDeleteFromList(invoice: Invoice) {
    setInvoices(state => state.filter(item => item.id !== invoice.id));
  }

  async function handleDelete() {
    if (selectedInvoice?.paid_at) {
      return;
    }

    try {
      setSaving(true);

      await api.delete(`/invoices/${selectedInvoice?.id}`);

      history.push('/invoices');
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  }

  return (
    <InvoicesProvider
      value={{
        selectedInvoice,
        setSelectedInvoice,
        handleUpdateList,
        handleDelete,
        saving,
        handleDeleteFromList,
      }}
    >
      {isOpenedSearchDialog && (
        <MobileSearch
          loading={loading}
          handleParamsChange={handleParamsChange}
          onExited={() => setIsOpenedSearchDialog(false)}
          params={params}
        />
      )}

      <Appbar title="Faturas" ActionsComponent={<InvoicesActions openDialog={() => setIsOpenedSearchDialog(true)} />} />

      <PageHeaderActions title="Faturas" description="Gestão das faturas" />

      <TableContainer tableTemplate={invoicesTableTemplate}>
        <InvoicesFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          handleParamsChange={handleParamsChange}
          params={params}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma fatura para mostrar" />
        ) : (
          <PaginationProvider>
            <Container>
              {displayMode === 'list' ? (
                <InvoiceListTable invoices={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <InvoiceListModule invoices={filtered} />
              )}
              <ApiPagination
                count={total}
                onChangePage={page => handleParamsChange('page', page)}
                onChangeRowsPerPage={rows => handleParamsChange('rows', rows)}
              />
            </Container>
          </PaginationProvider>
        )}
      </TableContainer>
    </InvoicesProvider>
  );
};

export default Invoices;
