import React from 'react';
import RestaurantModuleItem from './RestaurantModuleItem';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Restaurant } from 'types/restaurant';

type RestaurantModuleListProps = {
  restaurants: Restaurant[];
};

const RestaurantModuleList: React.FC<RestaurantModuleListProps> = ({ restaurants }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {restaurants.map(restaurant => (
            <RestaurantModuleItem key={restaurant.id} restaurant={restaurant} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default RestaurantModuleList;
