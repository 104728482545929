import React from 'react';
import { Typography } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { OrderIndexData } from 'hooks/useTableOrder';
import { usePagination } from 'providers/pagination';
import UserTableItem from './UserTableItem';
import { User } from 'types/user';
import { usersTableTemplate } from 'pages/users/usersTableTemplate';
import { makeStyles } from '@mui/styles';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type UsersTableListProps = {
  users: User[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const UsersTableList: React.FC<UsersTableListProps> = ({ users, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();

  return (
    <>
      <TableContent>
        <TableHeader>
          {usersTableTemplate.map(item => (
            <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId &&
                (orderedIndex.direction === 'asc' ? (
                  <ArrowUpward color="primary" />
                ) : (
                  <ArrowDownward color="primary" />
                ))}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
            <TableRow
              onClick={() => history.push(`/user/${user.id}`)}
              key={user.id}
              style={!user.active ? { opacity: 0.7 } : undefined}
            >
              <UserTableItem user={user} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default UsersTableList;
