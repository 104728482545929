import { InputAdornment, MenuItem, TextField, Theme } from '@mui/material';
import { Search } from '@mui/icons-material';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { useTable } from 'components/table/hook/useTable';
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  filter: {
    display: 'grid',
    gridTemplateColumns: '150px 200px',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '120px 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      flex: 1,
    },
  },
}));

type RestaurantsFilterBoxProps = {
  setIndexToSearch(index: string): void;
  indexToSearch: string;
  setSearchValue(value: string): void;
  searchValue: string;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
};

const RestaurantsFilterBox: React.FC<RestaurantsFilterBoxProps> = ({
  setIndexToSearch,
  indexToSearch,
  setDisplayMode,
  setSearchValue,
  searchValue,
  displayMode,
}) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [indexToSearch]);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField value={indexToSearch} onChange={e => setIndexToSearch(e.target.value)} select label="Pesquisar em">
          {tableTemplate
            .filter(col => !col.notSearchable)
            .map(item => (
              <MenuItem key={item.id} value={item.originalId}>
                {item.description}
              </MenuItem>
            ))}
        </TextField>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Pesquisar"
          autoFocus
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default RestaurantsFilterBox;
