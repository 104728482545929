import React from 'react';
import { Typography, Grid } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { OrderIndexData } from 'hooks/useTableOrder';
import { usePagination } from 'providers/pagination';
import { resourcesTableTemplate } from '../../resourcesTableTemplate';
import ResourceItemTable from './ResourceTableItem';
import { Resource } from 'types/resource';
import { makeStyles } from '@mui/styles';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type ResourceTableListProps = {
  resources: Resource[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const ResourceTableList: React.FC<ResourceTableListProps> = ({ resources, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContent>
          <TableHeader>
            {resourcesTableTemplate.map(item => (
              <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
                <Typography variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId &&
                  (orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  ))}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {resources.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(resource => (
              <TableRow onClick={() => history.push(`/resource/${resource.id}`)} key={resource.id}>
                <ResourceItemTable resource={resource} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </Grid>
    </Grid>
  );
};

export default ResourceTableList;
