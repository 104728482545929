import React, { FormEvent, MouseEvent, useState } from 'react';
import { TextField, Grid, Button } from '@mui/material';
import ProfileCustomizationImage from './RestaurantCustomizationImage';
import { makeStyles } from '@mui/styles';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';

const styles = makeStyles({
  linkContainer: {
    margin: '15px 0',
  },
});

const RestaurantCustomization: React.FC = () => {
  const classes = styles();
  const [dialogLogo, setDialogLogo] = useState(false);
  const { restaurant, validation, handleChange, handleValidation } = useRestaurant();

  function handleLinkClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setDialogLogo(true);
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleValidation();
  }

  return (
    <Grid container>
      {dialogLogo && <ProfileCustomizationImage onExited={() => setDialogLogo(false)} />}
      <Grid item xs={12} xl={3} lg={4} md={5} sm={7}>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            label="Cor primária"
            placeholder="Cor primária"
            margin="normal"
            fullWidth
            value={restaurant.primary_color || ''}
            onChange={event => handleChange('primary_color', event.target.value)}
            name="primary_color"
            error={!!validation.primary_color}
            helperText={validation.primary_color && validation.primary_color}
            type="color"
          />
          <TextField
            label="Cor secondária"
            placeholder="Cor secundária"
            margin="normal"
            fullWidth
            value={restaurant.secondary_color || ''}
            onChange={event => handleChange('secondary_color', event.target.value)}
            name="secondary_color"
            error={!!validation.secondary_color}
            helperText={validation.secondary_color && validation.secondary_color}
            type="color"
          />
          <button type="submit" style={{ display: 'none' }} />
        </form>
        <div className={classes.linkContainer}>
          <Button variant="text" onClick={handleLinkClick} color="primary" style={{ padding: 0 }}>
            {restaurant.image ? 'Alterar logo' : 'Inserir logo'}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default RestaurantCustomization;
