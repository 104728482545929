import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Restaurant } from 'types/restaurant';

type RestaurantsContextValue = {
  selectedRestaurant: Restaurant | null;
  setSelectedRestaurant: Dispatch<SetStateAction<Restaurant | null>>;
  handleActivateRestaurant(): void;
  handleDeactivateRestaurant(): void;
  handleUpdateList(restaurant: Restaurant): void;
};

const RestaurantsContext = createContext<RestaurantsContextValue>({} as RestaurantsContextValue);
export const RestaurantsProvider = RestaurantsContext.Provider;

export function useRestaurants(): RestaurantsContextValue {
  const context = useContext(RestaurantsContext);
  return context;
}
