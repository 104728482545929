import React, { useRef } from 'react';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, styled, TextField } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { InvoicesParams } from './Invoices';
import { useApp } from 'providers/app';

const Container = styled('div')(props => ({
  display: 'flex',
  gap: 10,
  [props.theme.breakpoints.down('sm')]: {
    flex: 1,
  },
  '& > .filter': {
    display: 'flex',
    gap: 10,
    [props.theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const OnlyPaidContent = styled('div')({
  display: 'flex',
  alignItems: 'self-end',
  marginLeft: 20,
});

type InvoicesFilterBoxProps = {
  setSearchValue(value: string): void;
  searchValue: string;
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  handleParamsChange(index: keyof InvoicesParams, value: any): void;
  params: InvoicesParams;
};

const InvoicesFilterBox: React.FC<InvoicesFilterBoxProps> = ({
  setDisplayMode,
  setSearchValue,
  displayMode,
  handleParamsChange,
  params,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const app = useApp();

  return (
    <FilterBox>
      <Container>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          fullWidth={app.isMobile}
          value={params.term}
          onChange={e => handleParamsChange('term', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: params.term.length ? (
              <InputAdornment position="end">
                <IconButton onClick={() => handleParamsChange('term', '')} size="small">
                  <Close />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
        />
        <div className="filter">
          <KeyboardDatePicker
            label="Data inicial"
            value={params.initial_date}
            onChange={date => handleParamsChange('initial_date', date)}
          />
          <KeyboardDatePicker
            label="Data Final"
            value={params.final_date}
            onChange={date => handleParamsChange('final_date', date)}
          />
          <OnlyPaidContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={params.only_paid}
                  onChange={e => handleParamsChange('only_paid', e.target.checked)}
                  color="primary"
                />
              }
              label="Pagos"
            />
          </OnlyPaidContent>
        </div>
      </Container>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default InvoicesFilterBox;
