import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import { addPhone } from 'store/apiContext/modules/restaurant/actions';

RestaurantActions.propTypes = {
  handleValidation: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
  handleDeleteImage: PropTypes.func.isRequired,
  imageSelected: PropTypes.bool,
};

export default function RestaurantActions({ handleValidation, tab, handleDeleteImage, imageSelected }) {
  const { dispatch } = useRestaurant();
  const { setDialogNew } = useRestaurant();

  function handleAddPhone() {
    dispatch(addPhone({ id: new Date().getTime(), phone: '' }));
  }

  return (
    <>
      {tab === 1 && (
        <Tooltip title="Adicionar endereço">
          <IconButton color="inherit" onClick={() => setDialogNew(true)}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {tab === 2 && (
        <Tooltip title="Adicionar telefone">
          <IconButton color="inherit" onClick={handleAddPhone}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Salvar restaurante">
        <IconButton color="inherit" onClick={handleValidation}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
