import React from 'react';
import { Tooltip, IconButton, Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const styles = makeStyles<Theme>(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

const RestaurantsActions: React.FC = () => {
  const classes = styles();

  return (
    <Tooltip title="Novo restaurante" className={classes.button}>
      <IconButton color="inherit" component={Link} to="/restaurant">
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RestaurantsActions;
