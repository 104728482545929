import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/NumberFormat';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Invoice } from 'types/invoice';
import { Paginated } from 'types/paginated';
import { InvoicesParams } from '../Invoices';

type UseFetchInvoices = {
  invoices: Invoice[];
  setInvoices: Dispatch<SetStateAction<Invoice[]>>;
  loading: boolean;
  total: number;
};

let timer: NodeJS.Timeout;

export function useFetchInvoices(params: InvoicesParams): UseFetchInvoices {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const fetch = useCallback(() => {
    setLoading(true);

    api
      .get<Paginated<Invoice[]>>('invoices', { params: { ...params, only_paid: params.only_paid ? 1 : 0 } })
      .then(response => {
        setTotal(response.data.total);
        setInvoices(
          response.data.data.map(invoice => {
            return {
              ...invoice,
              formattedCreatedAt: format(parseISO(invoice.created_at), 'P', { locale: ptBR }),
              formattedPaidAt: invoice.paid_at
                ? format(parseISO(invoice.paid_at as string), 'P', { locale: ptBR })
                : '',
              formattedPayday: format(parseISO(invoice.payday as any), 'P', { locale: ptBR }),
              formattedUpdatedAt: format(parseISO(invoice.updated_at), 'P', { locale: ptBR }),
              formattedValue: moneyFormat(invoice.value),
              status: !invoice.paid_at ? 'open' : 'paid',
            };
          }),
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [params]);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(fetch, 500);
  }, [fetch]);

  return { invoices, setInvoices, loading, total };
}
