import React, { useEffect, useRef } from 'react';
import { Grid, TextField } from '@mui/material';
import { Restaurant } from 'types/restaurant';
import { RestaurantValidation } from './validation/restaurantValidation';

type RestaurantFormProps = {
  restaurant: Restaurant;
  handleChange(index: keyof Restaurant, value: any): void;
  validation: RestaurantValidation;
  handleValidation(): void;
};

const RestaurantForm: React.FC<RestaurantFormProps> = ({ restaurant, handleChange, validation, handleValidation }) => {
  const inputs = {
    corporate_name: useRef<HTMLInputElement>(null),
    cnpj: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    working_hours: useRef<HTMLInputElement>(null),
    url: useRef<HTMLInputElement>(null),
    alias: useRef<HTMLInputElement>(null),
    play_store_link: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    keywords: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <form onSubmit={handleValidation}>
      <Grid item xl={4} lg={4} md={6} xs={12}>
        <TextField
          inputRef={inputs.corporate_name}
          error={!!validation.corporate_name}
          helperText={validation.corporate_name && validation.corporate_name}
          variant="standard"
          margin="normal"
          fullWidth
          label="Razão social"
          placeholder="Informe a razão social"
          value={restaurant.corporate_name || ''}
          onChange={event => handleChange('corporate_name', event.target.value)}
          autoFocus
        />
        <TextField
          inputRef={inputs.cnpj}
          error={!!validation.cnpj}
          helperText={validation.cnpj && validation.cnpj}
          variant="standard"
          margin="normal"
          fullWidth
          label="CNPJ"
          placeholder="Informe o CNPJ"
          value={restaurant.cnpj || ''}
          onChange={event => handleChange('cnpj', event.target.value)}
        />
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name && validation.name}
          variant="standard"
          name="name"
          margin="normal"
          fullWidth
          label="Nome"
          placeholder="Nome do Delivery"
          value={restaurant.name || ''}
          onChange={event => handleChange('name', event.target.value)}
        />
        <TextField
          inputRef={inputs.working_hours}
          error={!!validation.working_hours}
          helperText={validation.working_hours && validation.working_hours}
          variant="standard"
          name="working_hours"
          margin="normal"
          fullWidth
          label="Horário de atendimento"
          placeholder="Texto para horário de atendimento"
          value={restaurant.working_hours || ''}
          onChange={event => handleChange('working_hours', event.target.value)}
        />
        <TextField
          inputRef={inputs.url}
          error={!!validation.url}
          helperText={validation.url && validation.url}
          variant="standard"
          margin="normal"
          fullWidth
          label="URL"
          placeholder="URL do Delivery"
          value={restaurant.url || ''}
          onChange={event => handleChange('url', event.target.value)}
        />
        <TextField
          inputRef={inputs.alias}
          error={!!validation.alias}
          helperText={validation.alias ? validation.alias : 'Usado para compor a url, delivery.sgrande.app/alias'}
          variant="standard"
          margin="normal"
          fullWidth
          label="Alias"
          placeholder="Alias do restaurante"
          value={restaurant.alias || ''}
          onChange={event => handleChange('alias', event.target.value)}
        />
        <TextField
          inputRef={inputs.play_store_link}
          error={!!validation.play_store_link}
          helperText={validation.play_store_link && validation.play_store_link}
          variant="standard"
          margin="normal"
          fullWidth
          label="Play Store Link"
          placeholder="Play Store Link"
          value={restaurant.play_store_link || ''}
          onChange={event => handleChange('play_store_link', event.target.value)}
        />
        <TextField
          inputRef={inputs.email}
          error={!!validation.email}
          helperText={validation.email && validation.email}
          variant="standard"
          name="email"
          margin="normal"
          fullWidth
          label="E-mail de atendimento"
          placeholder="E-mail para atendimento dos clientes"
          value={restaurant.email || ''}
          onChange={event => handleChange('email', event.target.value)}
        />
        <TextField
          inputRef={inputs.description}
          error={!!validation.description}
          helperText={validation.description && validation.description}
          variant="standard"
          name="description"
          margin="normal"
          fullWidth
          label="Descrição"
          placeholder="Descrição do Delivery"
          value={restaurant.description || ''}
          onChange={event => handleChange('description', event.target.value)}
        />
        <TextField
          inputRef={inputs.title}
          error={!!validation.title}
          helperText={validation.title && validation.title}
          variant="standard"
          name="title"
          margin="normal"
          fullWidth
          label="Título"
          placeholder="Título da página inicial do Delivery"
          value={restaurant.title || ''}
          onChange={event => handleChange('title', event.target.value)}
        />
        <TextField
          inputRef={inputs.keywords}
          error={!!validation.keywords}
          helperText={
            validation.keywords
              ? validation.keywords
              : 'Keywords auxiliam os buscadores a indentificar o segmento de uma página da internet'
          }
          variant="standard"
          name="keywords"
          margin="normal"
          fullWidth
          label="Keywords"
          placeholder="Keywords"
          value={restaurant.keywords || ''}
          onChange={event => handleChange('keywords', event.target.value)}
          rows={4}
          multiline
        />
      </Grid>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default RestaurantForm;
