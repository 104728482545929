import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useApp } from 'providers/app';

type RestaurantTabsProps = {
  tab: number;
  handleTabChange(value: any): void;
};

const RestaurantTabs: React.FC<RestaurantTabsProps> = ({ tab, handleTabChange }) => {
  const app = useApp();

  return (
    <Tabs
      value={tab}
      onChange={(event, tab) => handleTabChange(tab)}
      variant={app.isMobile ? 'scrollable' : 'standard'}
      indicatorColor="secondary"
    >
      <Tab
        sx={{
          color: 'inherit',
        }}
        label="Info"
      />
      <Tab label="Endereços" />
      <Tab label="Telefones" />
      <Tab label="Personalização" />
      <Tab label="Plano" />
    </Tabs>
  );
};

export default RestaurantTabs;
