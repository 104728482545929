import React from 'react';
import { IconButton, Theme, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import history from 'services/history';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  add: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const PlansActions: React.FC = () => {
  const classes = useStyles();
  return (
    <Tooltip title="Adicionar" className={classes.add} onClick={() => history.push('/resource')}>
      <IconButton color="inherit">
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default PlansActions;
