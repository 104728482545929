import React from 'react';
import { Tooltip, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import PropTypes from 'prop-types';

UserActions.propTypes = {
  handleValidation: PropTypes.func.isRequired,
};

export default function UserActions({ handleValidation }) {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleValidation}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
