import React, { useState } from 'react';
import { lighten, Typography, useTheme } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { OrderIndexData } from 'hooks/useTableOrder';
import { usePagination } from 'providers/pagination';
import RestaurantTableItem from './RestaurantTableItem';
import { restaurantsTableTemplate } from 'pages/restaurants/restaurantsTableTemplate';
import { Restaurant } from 'types/restaurant';
import RestaurantListMenu from '../RestaurantListMenu';
import { makeStyles } from '@mui/styles';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type RestaurantTableListProps = {
  restaurants: Restaurant[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const RestaurantTableList: React.FC<RestaurantTableListProps> = ({ restaurants, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();

  return (
    <>
      <RestaurantListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContent>
        <TableHeader>
          {restaurantsTableTemplate.map(item => (
            <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId &&
                (orderedIndex.direction === 'asc' ? (
                  <ArrowUpward color="primary" />
                ) : (
                  <ArrowDownward color="primary" />
                ))}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {restaurants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(restaurant => (
            <TableRow
              style={!restaurant.active ? { background: lighten(theme.palette.error.light, 0.7) } : undefined}
              onClick={() => history.push(`/restaurant/${restaurant.id}`)}
              key={restaurant.id}
            >
              <RestaurantTableItem restaurant={restaurant} setAnchorEl={setAnchorEl} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default RestaurantTableList;
