import React from 'react';
import { Typography } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import history from 'services/history';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { OrderIndexData } from 'hooks/useTableOrder';
import { usePagination } from 'providers/pagination';
import { plansTableTemplate } from '../../plansTableTemplate';
import ResourceItemTable from './PlanTableItem';
import { Plan } from 'types/plan';
import { makeStyles } from '@mui/styles';
import TableContent from 'components/table/TableContent';
import TableBody from 'components/table/TableBody';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type PlanTableListProps = {
  plans: Plan[];
  handleSort(id: string): void;
  orderedIndex: OrderIndexData;
};

const PlanTableList: React.FC<PlanTableListProps> = ({ plans, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { page, rowsPerPage } = usePagination();

  return (
    <>
      <TableContent>
        <TableHeader>
          {plansTableTemplate.map(item => (
            <div className={classes.headerItem} key={item.id} onClick={() => handleSort(item.originalId)}>
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId &&
                (orderedIndex.direction === 'asc' ? (
                  <ArrowUpward color="primary" />
                ) : (
                  <ArrowDownward color="primary" />
                ))}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {plans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(plan => (
            <TableRow onClick={() => history.push(`/plan/${plan.id}`)} key={plan.id}>
              <ResourceItemTable plan={plan} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default PlanTableList;
