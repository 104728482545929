import { TableTemplate } from 'types/tableTemplate';

export const invoicesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 50,
  },
  {
    id: 'status',
    description: 'STATUS',
    originalId: 'status',
    width: 100,
  },
  {
    id: 'restaurant_name',
    description: 'RESTAURANTE',
    originalId: 'restaurant_name',
    width: 300,
  },
  {
    id: 'formattedPayday',
    description: 'VENCIMENTO',
    originalId: 'payday',
    width: 150,
  },
  {
    id: 'formattedValue',
    description: 'VALOR',
    originalId: 'value',
    width: 150,
  },
  {
    id: 'formattedPaidAt',
    description: 'PAGO EM',
    originalId: 'paid_at',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 200,
    notSearchable: true,
  },
];
