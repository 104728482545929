import React from 'react';
import PlanModuleItem from './PlanModuleItem';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Plan } from 'types/plan';

type PlanModuleListProps = {
  plans: Plan[];
};

const PlanModuleList: React.FC<PlanModuleListProps> = ({ plans }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {plans.map(plan => (
            <PlanModuleItem key={plan.id} plan={plan} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PlanModuleList;
