import React, { useState } from 'react';
import MessagingProvider from 'providers/messaging';
import { theme } from 'config/theme';
import Routes from 'routes/Routes';
import { AppProvider } from 'providers/app';
import history from 'services/history';
import { BrowserRouter } from 'routes/BrowserRouter';
import { ThemeProvider } from '@mui/material';
import { useWindowSize } from 'hooks/useWindowSize';
import { mobileCheck } from 'helpers/mobileCheck';

export const menuWidth = 300;

const App: React.FC = () => {
  const { isMobile, width: windowWidth } = useWindowSize();
  const [isOpenedMenu, setIsOpenedMenu] = useState(!mobileCheck());

  const appContextValue = {
    isOpenedMenu,
    isMobile,
    windowWidth,
    handleOpenMenu,
  };

  function handleOpenMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  return (
    <BrowserRouter history={history}>
      <AppProvider value={appContextValue}>
        <ThemeProvider theme={theme}>
          <MessagingProvider>
            <Routes />
          </MessagingProvider>
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
