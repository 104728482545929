import { Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Resource } from 'types/resource';

type PlanFormResourcesProps = {
  resources: Resource[];
  handleResourceChange(id: number): void;
};

const useStyles = makeStyles({
  formControl: {
    paddingLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 0 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rules: {
    marginTop: 20,
  },
  title: {
    textTransform: 'uppercase',
  },
});

const PlanFormResources: React.FC<PlanFormResourcesProps> = ({ resources, handleResourceChange }) => {
  const classes = useStyles();

  return (
    <>
      {resources.map(resource => (
        <div key={resource.id} className={classes.formControl}>
          <div className={classes.header}>
            <Typography className={classes.title} variant="body2">
              {resource.title}
            </Typography>
            <Switch color="primary" onChange={() => handleResourceChange(resource.id)} checked={resource.enabled} />
          </div>
          <Typography variant="body2" color="textSecondary">
            {resource.description}
          </Typography>
          {resource.resources.length > 0 && (
            <PlanFormResources resources={resource.resources} handleResourceChange={handleResourceChange} />
          )}
        </div>
      ))}
    </>
  );
};

export default PlanFormResources;
