import React, { Dispatch, SetStateAction, MouseEvent } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Invoice } from 'types/invoice';
import { useInvoices } from 'pages/invoices/hooks/useInvoices';

interface StatusContainerProps {
  status: 'paid' | 'open';
}

const StatusContainer = styled('div')<StatusContainerProps>(props => ({
  backgroundColor: props.status === 'paid' ? '#8BC34A' : '#ffc107',
  color: '#fff',
  padding: 7,
  fontSize: 12,
  textAlign: 'center',
  marginRight: 7,
}));

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  numericData: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginRight: 20,
  },
});

type InvoiceItemTableProps = {
  invoice: Invoice;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const InvoiceItemTable: React.FC<InvoiceItemTableProps> = ({ invoice, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedInvoice } = useInvoices();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedInvoice(invoice);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'status' ? (
            <StatusContainer key={item.id} status={invoice.status as any}>
              {invoice.status === 'open' ? 'aberto' : 'pago'}
            </StatusContainer>
          ) : (
            <div key={item.id} className={item.dataType === 'number' ? classes.numericData : undefined}>
              <Typography variant="body2">{invoice[item.id]}</Typography>
            </div>
          ),
        )}
    </>
  );
};

export default InvoiceItemTable;
