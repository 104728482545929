import React from 'react';
import RestaurantModuleItem from './UserModuleItem';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { User } from 'types/user';

type UserModuleListProps = {
  users: User[];
};

const UserModuleList: React.FC<UserModuleListProps> = ({ users }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {users.map(user => (
            <RestaurantModuleItem key={user.id} user={user} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default UserModuleList;
