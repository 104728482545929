import { Dispatch, useReducer } from 'react';
import { Restaurant, RestaurantAddress } from 'types/restaurant';
import { RestaurantActionsType } from './type';

export const INITIAL_STATE: Restaurant = {
  ...({} as Restaurant),
  addresses: [],
  phones: [],
} as Restaurant;

export default function restaurantReducer(state = INITIAL_STATE, action: RestaurantActionsType): Restaurant {
  switch (action.type) {
    case 'SET_RESTAURANT': {
      return action.restaurant;
    }

    case 'RESTAURANT_CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_ADDRESS': {
      let address: RestaurantAddress;
      if (state.addresses.length === 0) address = { ...action.address, id: new Date().getTime(), is_main: true };
      else address = { ...action.address, id: new Date().getTime(), is_main: false };
      return {
        ...state,
        addresses: [...state.addresses, address],
      };
    }

    case 'DELETE_ADDRESS': {
      const addresses = state.addresses.filter(address => address.id !== action.addressId);
      return {
        ...state,
        addresses,
      };
    }

    case 'EDIT_ADDRESS': {
      const addresses = state.addresses.map(address => (address.id === action.address.id ? action.address : address));
      return {
        ...state,
        addresses,
      };
    }

    case 'ADD_PHONE': {
      return {
        ...state,
        phones: [
          ...state.phones,
          {
            ...action.phone,
            id: new Date().getTime(),
          },
        ],
      };
    }

    case 'DELETE_PHONE': {
      const phones = state.phones.filter(phone => phone.id !== action.phoneId);
      return {
        ...state,
        phones,
      };
    }

    case 'EDIT_PHONE': {
      const phones = state.phones.slice();
      phones[action.index] = { ...phones[action.index], phone: action.value };

      return {
        ...state,
        phones,
      };
    }

    case 'IMAGE_SELECT': {
      return {
        ...state,
        imageSelected: !state.imageSelected,
      };
    }

    case 'IMAGE_DELETE': {
      return {
        ...state,
        image: null,
        imageSelected: false,
      };
    }

    default: {
      return state;
    }
  }
}

type UseRestaurantReducer = [Restaurant, Dispatch<RestaurantActionsType>];

export function useRestaurantReducer(): UseRestaurantReducer {
  const [restaurant, dispatch] = useReducer(restaurantReducer, INITIAL_STATE);
  return [restaurant, dispatch];
}
