import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Invoice } from 'types/invoice';

export interface InvoicesContextValue {
  selectedInvoice: Invoice | null;
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | null>>;
  handleUpdateList(invoice: Invoice): void;
  handleDelete(): Promise<void>;
  saving: boolean;
  handleDeleteFromList(invoice: Invoice): void;
}

const InvoicesContext = createContext<InvoicesContextValue>({} as InvoicesContextValue);
export const InvoicesProvider = InvoicesContext.Provider;
export const InvoicesConsumer = InvoicesContext.Consumer;

export function useInvoices(): InvoicesContextValue {
  return useContext(InvoicesContext);
}
