import { Tab, Tabs } from '@mui/material';
import React from 'react';

type PlanTabsProps = {
  tab: number;
  handleTabChange(value): void;
};

const PLanTabs: React.FC<PlanTabsProps> = ({ tab, handleTabChange }) => {
  return (
    <Tabs onChange={(e, value) => handleTabChange(value)} value={tab}>
      <Tab label="Plano" />
      <Tab label="Recursos" />
    </Tabs>
  );
};

export default PLanTabs;
