import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { pt } from 'date-fns/locale';

interface KeyboardDatePickerProps {
  value: Date | null;
  onChange(date: Date | null): void;
  label: string;
  fullWidth?: boolean;
  margin?: 'normal';
  disabled?: boolean;
}

const KeyboardDatePicker: React.FC<KeyboardDatePickerProps> = ({
  value,
  onChange,
  label,
  fullWidth = false,
  margin = undefined,
  disabled = false,
}) => {
  return (
    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        openTo="day"
        views={['day']}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
        renderInput={params => <TextField margin={margin} fullWidth={fullWidth} {...params} />}
      />
    </LocalizationProvider>
  );
};

export default KeyboardDatePicker;
