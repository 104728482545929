import { Dispatch, SetStateAction, useState } from 'react';
import { RestaurantAddress } from 'types/restaurant';
import * as yup from 'yup';

export interface RestaurantAddressValidation {
  postal_code?: string;
  address?: string;
  number?: string;
  district?: string;
  city?: string;
  region?: string;
  nickname?: string;
}

type UseRestaurantAddressValidation = [
  RestaurantAddressValidation,
  Dispatch<SetStateAction<RestaurantAddressValidation>>,
  (address: RestaurantAddress) => Promise<void>,
];

export function useRestaurantAddressValidation(): UseRestaurantAddressValidation {
  const [validation, setValidation] = useState<RestaurantAddressValidation>({} as RestaurantAddressValidation);

  async function handleValidation(address: RestaurantAddress) {
    const schema = yup.object().shape({
      region: yup.string().required('A região é obrigatória'),
      city: yup.string().required('A cidade é obrigatória'),
      district: yup.string().required('O bairro é obrigatório'),
      number: yup.string().required('O número é obrigatório'),
      address: yup.string().required('O endereço é obrigatório'),
      postal_code: yup.string().required('O CEP é obrigatório'),
      nickname: yup.string().required('O apelido é obrigatório'),
    });

    try {
      await schema.validate(address);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }
      throw err;
    }
  }

  return [validation, setValidation, handleValidation];
}
