import React, { HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    background: '#fff',
    marginTop: 10,
  },
});

interface NoDataProps extends HTMLAttributes<HTMLDivElement> {
  message: string;
}

const NoData: React.FC<NoDataProps> = ({ message, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} {...rest}>
      <Typography>{message}</Typography>
    </div>
  );
};

export default NoData;
