import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Users from 'pages/users/Users';
import UserNew from 'pages/users/registration/new/User';
import UserEdit from 'pages/users/registration/edit/User';
import Dashboard from 'pages/dashboard/Dashboard';
import Index from 'pages/index/Index';
import Login from 'pages/login/Login';
import NotFound from 'pages/notfound/NotFound';
import Restaurants from 'pages/restaurants/Restaurants';
import RestaurantNew from 'pages/restaurants/registration/new/Restaurant';
import RestaurantEdit from 'pages/restaurants/registration/edit/Restaurant';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import Plans from 'pages/plans/Plans';
import Resources from 'pages/resources/Resources';
import ResourceNew from 'pages/resources/registration/new/ResourceNew';
import ResourceUpdate from 'pages/resources/registration/update/ResourceUpdate';
import PlanUpdate from 'pages/plans/registration/update/PlanUpdate';
import PlanNew from 'pages/plans/registration/new/PlanNew';
import DefaultImages from 'pages/default-images/DefaultImages';
import Invoices from 'pages/invoices/Invoices';
import EditInvoice from 'pages/invoices/registration/edit/EditInvoice';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<ProtectedRoute element={<Index />} />} />
    <Route path="/login" element={<PublicRoute element={<Login />} />} />
    <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
    <Route path="/restaurants" element={<ProtectedRoute element={<Restaurants />} />} />
    <Route path="/restaurant" element={<ProtectedRoute element={<RestaurantNew />} />} />
    <Route path="/restaurant/:id" element={<ProtectedRoute element={<RestaurantEdit />} />} />
    <Route path="/users" element={<ProtectedRoute element={<Users />} />} />
    <Route path="/user" element={<ProtectedRoute element={<UserNew />} />} />
    <Route path="/user/:id" element={<ProtectedRoute element={<UserEdit />} />} />
    <Route path="/resources" element={<ProtectedRoute element={<Resources />} />} />
    <Route path="/resource" element={<ProtectedRoute element={<ResourceNew />} />} />
    <Route path="/resource/:id" element={<ProtectedRoute element={<ResourceUpdate />} />} />
    <Route path="/plans" element={<ProtectedRoute element={<Plans />} />} />
    <Route path="/plan/:id" element={<ProtectedRoute element={<PlanUpdate />} />} />
    <Route path="/plan" element={<ProtectedRoute element={<PlanNew />} />} />
    <Route path="/config/default-images" element={<ProtectedRoute element={<DefaultImages />} />} />
    <Route path="/invoices" element={<ProtectedRoute element={<Invoices />} />} />
    <Route path="/invoices/:id" element={<ProtectedRoute element={<EditInvoice />} />} />
    <Route element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
