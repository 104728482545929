import React, { useState, useReducer, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import UserForm from '../UserForm';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import userReducer, { INITIAL_STATE as userIntialState } from 'store/apiContext/modules/user/reducer';
import { userChange, setUser } from 'store/apiContext/modules/user/actions';
import UserActions from './UserActions';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessaging } from 'providers/messaging';
import PageHeader from 'components/page-header/PageHeader';
import { UserContextProvider } from 'pages/users/hook/useUser';

export default function User() {
  const [user, dispatch] = useReducer(userReducer, userIntialState);
  const [validation, setValidation] = useState({});
  const [saving, setSaving] = useState(false);
  const [userLoading, setUserloading] = useState(true);
  const messaging = useMessaging();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!user.restaurant) {
      return;
    }
    dispatch(userChange('restaurant_id', user.restaurant.id));
  }, [user.restaurant]);

  useEffect(() => {
    api
      .get(`/users/${id}`)
      .then(response => {
        dispatch(setUser(response.data));
      })
      .finally(() => {
        setUserloading(false);
      });
  }, [id]);

  function handleChange(index, value) {
    dispatch(userChange(index, value));
  }

  function handleValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      restaurant_id: yup.string().required('O restaurante é obrigatório'),
      phone: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue.replace(/\D/g, '');
        })
        .min(10, 'Telefone inválido')
        .required('O telefone é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(user)
      .then(() => {
        setValidation({});
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    const data = {
      ...user,
      password_confirmation: user.passwordConfirmation,
    };

    api
      .put(`/users/${id}`, data)
      .then(() => {
        messaging.handleOpen('Salvo');
        navigate('/users');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleBlock() {
    setSaving(true);
    api
      .put(`users/status/${user.id}`)
      .then(() => {
        messaging.handleOpen('Usuário atualizado');
        setSaving(false);
        navigate('/users');
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível atualizar o usuário');
        setSaving(false);
      });
  }

  return (
    <UserContextProvider value={{ validation, user, handleChange }}>
      {saving && <Loading background="rgba(250,250,250,0.5)" />}
      <CustomAppbar
        title="Editar usuário"
        ActionsComponent={
          <UserActions handleValidation={handleValidation} handleBlock={handleBlock} activated={user.active} />
        }
      />
      <PageHeader
        title="Editar usuário"
        backUrl="/users"
        description="Edite usuário que tem acesso a admin.sgrande.delivery"
      />
      {userLoading ? (
        <InsideLoading />
      ) : (
        <UserForm validation={validation} handleChange={handleChange} handleValidation={handleValidation} user={user} />
      )}
    </UserContextProvider>
  );
}
