export function userChange(index, value) {
  return {
    type: 'CHANGE',
    index,
    value,
  };
}

export function setUser(user) {
  return {
    type: 'SET_USER',
    user,
  };
}
