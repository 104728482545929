import { TableTemplate } from 'types/tableTemplate';

export const restaurantsTableTemplate: TableTemplate[] = [
  { id: 'actions', description: 'AÇÕES', originalId: 'actions', width: 60 },
  { id: 'id', description: 'ID', originalId: 'id', width: 50 },
  { id: 'name', description: 'NOME', originalId: 'name', width: 200 },
  { id: 'description', description: 'DESCRIÇÃO', originalId: 'description', width: 300 },
  { id: 'keywords', description: 'KEYWORDS', originalId: 'keywords', width: 300 },
  { id: 'title', description: 'TÍTULO', originalId: 'title', width: 200 },
  { id: 'url', description: 'URL', originalId: 'url', width: 400 },
  { id: 'cnpj', description: 'CNPJ', originalId: 'cnpj', width: 200 },
  { id: 'corporate_name', description: 'RAZÃO SOCIAL', originalId: 'corporate_name', width: 300 },
  { id: 'alias', description: 'ALIAS', originalId: 'alias', width: 200 },
  { id: 'email', description: 'E-MAIL', originalId: 'email', width: 300 },
  { id: 'primary_color', description: 'COR PRIMÁRIA', originalId: 'primary_color', width: 150 },
  { id: 'secondary_color', description: 'COR SECUNDÁRIA', originalId: 'secondary_color', width: 150 },
  { id: 'play_store_link', description: 'PLAY STORE', originalId: 'play_store_link', width: 500 },
  { id: 'app_store_link', description: 'APP STORE', originalId: 'app_store_link', width: 500 },
  { id: 'formattedCreatedAt', description: 'DATA', originalId: 'created_at', width: 300 },
];
