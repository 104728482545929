import React, { useEffect, useRef } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Resource } from 'types/resource';
import { ResourceValidation } from './validation/resourceValidation';

type ResourceFormProps = {
  resource: Resource;
  handleChange(index: string, value: string): void;
  validation: ResourceValidation;
  resources: Resource[];
};

const ResourceForm: React.FC<ResourceFormProps> = ({ resource, handleChange, validation, resources }) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <TextField
        select
        label="Pertence a"
        fullWidth
        value={resource.resource_id || ''}
        onChange={e => handleChange('resource_id', e.target.value)}
        margin="normal"
      >
        {resources.map(res => (
          <MenuItem value={res.id} key={res.id}>
            {res.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name}
        label="Nome do recurso"
        placeholder="Digite o nome da recurso"
        margin="normal"
        autoFocus
        fullWidth
        value={resource.name || ''}
        onChange={e => handleChange('name', e.target.value)}
      />
      <TextField
        inputRef={inputs.title}
        error={!!validation.title}
        helperText={validation.title}
        label="Título"
        placeholder="Digite o título"
        margin="normal"
        fullWidth
        value={resource.title || ''}
        onChange={e => handleChange('title', e.target.value)}
        autoComplete="none"
      />
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Descrição"
        placeholder="Digite a descrição do recurso"
        margin="normal"
        fullWidth
        value={resource.description || ''}
        onChange={e => handleChange('description', e.target.value)}
      />
    </>
  );
};

export default ResourceForm;
