import { Dispatch, SetStateAction, useState } from 'react';
import { Invoice } from 'types/invoice';
import * as yup from 'yup';

export type InvoiceValidation = {
  payday?: string;
  value?: string;
};

type UseInvoiceValidation = [
  InvoiceValidation,
  Dispatch<SetStateAction<InvoiceValidation>>,
  (invoice: Invoice) => Promise<void>,
];

export function useInvoiceValidation(): UseInvoiceValidation {
  async function handleValidation(invoice: Invoice) {
    const schema = yup.object().shape({
      payday: yup.date().required('O vencimento da fatura é obrigatório'),
      value: yup.number().required('A valor da fatura é obrigatório'),
    });

    try {
      await schema.validate(invoice);
    } catch (err: any) {
      setValidation({
        [err.path]: err.message,
      });
      throw err;
    }
  }

  const [validation, setValidation] = useState<InvoiceValidation>({});
  return [validation, setValidation, handleValidation];
}
