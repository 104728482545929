import React, { useCallback, useState } from 'react';
import RestaurantItem from './RestaurantItem';
import styles from './autocomplete.module.css';
import { Autocomplete, TextField } from '@mui/material';
import CategoryAutoCompleteAdornment from './RestaurantAutocompleteAdornment';
import { api } from 'services/api';
import { Restaurant } from 'types/restaurant';

let timer: NodeJS.Timeout;

interface AutocompleteFromProps {
  onSelect(restaurant: Restaurant | null): void;
  label: string;
  placeholder: string;
  value: Restaurant | null;
}

const RestaurantAutocomplete: React.FC<AutocompleteFromProps> = ({ onSelect, label, placeholder, value }) => {
  const [items, setItems] = useState<Restaurant[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSelect = useCallback(
    (restaurant: Restaurant) => {
      onSelect(restaurant);
      setItems([]);
    },
    [onSelect],
  );

  const fetch = useCallback(async (term?: string) => {
    setLoading(true);

    await api
      .get('/restaurants', { params: { term } })
      .then(response => setItems(response.data))
      .finally(() => setLoading(false));
  }, []);

  const clearSelected = useCallback(() => {
    onSelect(null);
  }, [onSelect]);

  function handleChange(value: string) {
    clearTimeout(timer);

    if (value.length < 3) {
      return;
    }

    timer = setTimeout(() => fetch(value), 400);
  }

  return (
    <Autocomplete
      classes={{ inputRoot: styles.inputRoot }}
      id="category-autocomplete"
      open={items.length > 0}
      options={items}
      value={value ?? null}
      fullWidth
      filterOptions={categories => categories}
      getOptionLabel={item => item.name}
      renderOption={(props, option) => <RestaurantItem handleSelect={handleSelect} option={option} {...props} />}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          onChange={event => handleChange(event.target.value)}
          onBlur={() => setItems([])}
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <CategoryAutoCompleteAdornment selected={value} loading={loading} clearSelected={clearSelected} />
            ),
          }}
        />
      )}
    />
  );
};

export default RestaurantAutocomplete;
