import React from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import { useAuth } from 'providers/auth';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  return (
    <>
      <CustomAppbar title="Dashboard" />
      <PageHeader title={user ? `Olá ${user?.name}` : 'carregando...'} />
    </>
  );
};

export default Dashboard;
