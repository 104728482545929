import React, { useState } from 'react';
import List from 'components/list/List';
import CategoryItemModule from './InvoiceItemModule';
import { Invoice } from 'types/invoice';
import InvoiceListMenu from '../InvoiceListMenu';

type InvoiceListModuleProps = {
  invoices: Invoice[];
};

const InvoiceListModule: React.FC<InvoiceListModuleProps> = ({ invoices }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <List>
      <InvoiceListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      {invoices.map(invoice => (
        <CategoryItemModule setAnchorEl={setAnchorEl} key={invoice.id} invoice={invoice} />
      ))}
    </List>
  );
};

export default InvoiceListModule;
