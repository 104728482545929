import React, { useState } from 'react';
import { Typography } from '@mui/material';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import InvoicesItemTable from './InvoicesItemTable';
import { Invoice } from 'types/invoice';
import { OrderIndexData } from 'hooks/useTableOrder';
import InvoiceListMenu from '../InvoiceListMenu';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  headerItemNumeric: {
    justifyContent: 'flex-end',
    marginRight: 20,
  },
});

type InvoiceListTableProps = {
  invoices: Invoice[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const InvoiceListTable: React.FC<InvoiceListTableProps> = ({ invoices, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const history = useNavigate();
  const { tableTemplate } = useTable();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <InvoiceListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={
                item.dataType === 'number' ? `${classes.headerItem} ${classes.headerItemNumeric}` : classes.headerItem
              }
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {invoices.map(invoice => (
            <TableRow key={invoice.id} onClick={() => history(`/invoices/${invoice.id}`)}>
              <InvoicesItemTable setAnchorEl={setAnchorEl} invoice={invoice} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default InvoiceListTable;
