import React, { useState, useReducer, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import UserForm from '../UserForm';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import userReducer, { INITIAL_STATE as userIntialState } from 'store/apiContext/modules/user/reducer';
import { userChange } from 'store/apiContext/modules/user/actions';
import UserActions from './UserActions';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useMessaging } from 'providers/messaging';
import PageHeader from 'components/page-header/PageHeader';
import { UserContextProvider } from 'pages/users/hook/useUser';

export default function User() {
  const [user, dispatch] = useReducer(userReducer, userIntialState);
  const [validation, setValidation] = useState({});
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.restaurant) {
      return;
    }
    dispatch(userChange('restaurant_id', user.restaurant.id));
  }, [user.restaurant]);

  function handleChange(index, value) {
    dispatch(userChange(index, value));
  }

  function handleValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      restaurant_id: yup.string().required('O restaurante é obrigatório'),
      passwordConfirmation: yup
        .string()
        .min(4, 'A senha deve ter no mínimo 4 caracteres')
        .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais')
        .required('A confirmação da senha é obrigatória'),
      password: yup.string().min(4, 'A senha deve ter no mínino 4 caracteres').required('A senha é obrigatória'),
      phone: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue.replace(/\D/g, '');
        })
        .min(10, 'Telefone inválido')
        .required('O telefone é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('O e-mail é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(user)
      .then(() => {
        setValidation({});
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
        console.log(err);
      });
  }

  function handleSubmit() {
    setSaving(true);

    const data = {
      ...user,
      password_confirmation: user.passwordConfirmation,
    };

    api
      .post(`/users`, data)
      .then(() => {
        messaging.handleOpen('Salvo');
        navigate('/users');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <UserContextProvider value={{ validation, user, handleChange }}>
      {saving && <Loading background="rgba(250,250,250,0.5)" />}
      <CustomAppbar title="Novo usuário" ActionsComponent={<UserActions handleValidation={handleValidation} />} />
      <PageHeader
        title="Adicionar usuário"
        backUrl="/users"
        description="Adicione um novo usuário para ter acesso a admin.sgrande.delivery"
      />
      <UserForm mode="new" />
    </UserContextProvider>
  );
}
