import React from 'react';
import MaskedInput from 'react-text-mask';

const mask = function (rawValue) {
  const value = rawValue.replace(/\D/g, '');

  if (value.length <= 10) return [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  else return [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

const PhoneInput = (props, ref) => {
  return <MaskedInput ref={ref} {...props} mask={mask} placeholderChar={'\u2000'} showMask={false} />;
};

export default React.forwardRef(PhoneInput);
