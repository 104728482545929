import { createContext, useContext } from 'react';
import { User } from 'types/user';
import { UserValidation } from '../registration/validation/userValidation';

type UserContextValue = {
  validation: UserValidation;
  user: User;
  handleChange(index: keyof User, value): void;
};

const UserContext = createContext<UserContextValue>({} as UserContextValue);
export const UserContextProvider = UserContext.Provider;

export function useUser(): UserContextValue {
  const context = useContext(UserContext);
  return context;
}
