import React from 'react';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import Dialog from 'components/dialog/Dialog';
import { InvoicesParams } from './Invoices';

type MobileSearchProps = {
  onExited(): void;
  handleParamsChange(index: keyof InvoicesParams, value: any): void;
  params: InvoicesParams;
  loading: boolean;
};

const MobileSearch: React.FC<MobileSearchProps> = ({ onExited, handleParamsChange, params, loading }) => {
  return (
    <Dialog maxWidth="sm" onExited={onExited} title="Pesquisar" fullScreen>
      <Box display="flex" flexDirection="column" gap="10px">
        <KeyboardDatePicker
          label="Data inicial"
          value={params.initial_date || null}
          onChange={date => handleParamsChange('initial_date', date)}
          margin="normal"
        />

        <KeyboardDatePicker
          label="Data Final"
          value={params.final_date || null}
          onChange={date => handleParamsChange('final_date', date)}
          margin="normal"
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={loading}
              checked={params.only_paid}
              onChange={e => handleParamsChange('only_paid', e.target.checked)}
              color="primary"
            />
          }
          label="Pagos"
        />
      </Box>
    </Dialog>
  );
};

export default MobileSearch;
