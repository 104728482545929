import { TableTemplate } from 'types/tableTemplate';

export const usersTableTemplate: TableTemplate[] = [
  { id: 'id', description: 'ID', originalId: 'id', width: 50 },
  { id: 'name', description: 'NOME', originalId: 'name', width: 300 },
  { id: 'email', description: 'E-MAIL', originalId: 'email', width: 300 },
  { id: 'restaurantName', description: 'RESTAURANTE', originalId: 'restaurantName', width: 300 },
  { id: 'phone', description: 'TELEFONE', originalId: 'phone', width: 150 },
  { id: 'rule', description: 'RULE', originalId: 'rule', width: 150 },
  { id: 'type', description: 'TYPE', originalId: 'type', width: 150 },
  { id: 'formattedActivated', description: 'ATIVO', originalId: 'formattedActivated', width: 150 },
];
