import React, { HTMLAttributes } from 'react';
import { IconButton, Theme } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface DisplayModeButtonsProps extends HTMLAttributes<HTMLDivElement> {
  handleShowList(): void;
  handleShowModule(): void;
  displayMode: 'list' | 'module';
}

const DisplayModeButtons: React.FC<DisplayModeButtonsProps> = ({
  handleShowList,
  handleShowModule,
  displayMode,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className}`} {...rest}>
      <IconButton onClick={handleShowList}>
        <ViewListIcon color={displayMode === 'list' ? 'primary' : undefined} />
      </IconButton>
      <IconButton onClick={handleShowModule}>
        <ViewModuleIcon color={displayMode === 'module' ? 'primary' : undefined} />
      </IconButton>
    </div>
  );
};

export default DisplayModeButtons;
