import React from 'react';
import { Typography, ListItemButton, styled } from '@mui/material';
import history from 'services/history';
import { Resource } from 'types/resource';

type ResourceModuleItemProps = {
  resource: Resource;
};

const ListItemStyled = styled(ListItemButton)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
}));

const ResourceModuleItem: React.FC<ResourceModuleItemProps> = ({ resource }) => {
  return (
    <ListItemStyled onClick={() => history.push(`/resource/${resource.id}`)}>
      <Typography variant="h6">{resource.name}</Typography>
      <Typography variant="body1">{resource.title}</Typography>
      <Typography variant="caption" color="textSecondary">
        {resource.description}
      </Typography>
    </ListItemStyled>
  );
};

export default ResourceModuleItem;
