import React from 'react';
import { Typography } from '@mui/material';
import { usersTableTemplate } from '../../usersTableTemplate';
import { User } from 'types/user';

type RestaurantTableItemProps = {
  user: User;
};

const RestaurantTableItem: React.FC<RestaurantTableItemProps> = ({ user }) => {
  return (
    <>
      {usersTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{user[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default RestaurantTableItem;
