import Appbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMessaging } from 'providers/messaging';
import React, { useState, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'services/api';
import history from 'services/history';
import { Invoice } from 'types/invoice';
import InvoiceForm from '../InvoiceForm';
import { useInvoiceValidation } from '../validation/invoiceValidation';
import InvoiceActions from './InvoiceActions';

const invoiceInitialValue: Invoice = {
  created_at: '',
  value: 0,
  updated_at: '',
  type: 'monthly_payment',
  restaurant_id: 0,
  payday: null,
  paid_at: '',
  formattedValue: '',
  formattedUpdatedAt: '',
  description: '',
  formattedCreatedAt: '',
  formattedPaidAt: '',
  formattedPayday: '',
  id: 0,
};

const EditInvoice: React.FC = () => {
  const [invoice, setInvoice] = useState<Invoice>(invoiceInitialValue);
  const [loading, setLoading] = useState(true);
  const [validation, setValidation, validate] = useInvoiceValidation();
  const { id } = useParams<{ id: string }>();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    api
      .get(`/invoices/${id}`)
      .then(response => {
        setInvoice({
          ...response.data,
          formattedCreatedAt: format(parseISO(response.data.created_at), 'P', { locale: ptBR }),
          payday: parseISO(response.data.payday),
        });
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  function handleChange(index: keyof Invoice, value: any) {
    setInvoice(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleValidation(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    setValidation({});

    validate(invoice)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    if (invoice.paid_at) {
      return;
    }

    setSaving(true);

    api
      .put(`/invoices/${id}`, invoice)
      .then(() => {
        history.push('/invoices');
      })
      .catch(err => {
        setSaving(false);
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar a fatura');
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/invoices/${id}`)
      .then(() => {
        history.push('/invoices');
      })
      .catch(err => console.error(err));
  }

  return (
    <>
      <Appbar
        title="Faturas"
        ActionsComponent={
          <InvoiceActions handleDelete={handleDelete} isPaid={!!invoice.paid_at} handleValidation={handleValidation} />
        }
      />

      <PageHeader backUrl="/invoices" title="Editar fatura" description="Alterar valor ou vecimento de uma fatura" />

      {saving && <Loading />}

      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidation}>
          <InvoiceForm handleChange={handleChange} invoice={invoice} validation={validation} />
        </form>
      )}
    </>
  );
};

export default EditInvoice;
