import React from 'react';
import { ListItemButton, styled, Typography } from '@mui/material';
import { Store } from '@mui/icons-material';
import { Restaurant } from 'types/restaurant';

const ListItemStyled = styled(ListItemButton)({
  alignItems: 'center',
  gap: 10,
  justifyContent: 'flex-start',
  '& svg': {
    color: '#ccc',
  },
});

interface RestaurantItemProps {
  option: Restaurant;
  handleSelect(restaurant: Restaurant): void;
}

const RestaurantItem: React.FC<RestaurantItemProps> = ({ option, handleSelect }) => {
  return (
    <ListItemStyled onClick={() => handleSelect(option)}>
      <Store />
      <div>
        <Typography>{option.name}</Typography>
      </div>
    </ListItemStyled>
  );
};

export default RestaurantItem;
