import React from 'react';
import { Typography } from '@mui/material';
import { plansTableTemplate } from '../../plansTableTemplate';
import { Plan } from 'types/plan';

type PlanTableItemProps = {
  plan: Plan;
};

const PlanTableItem: React.FC<PlanTableItemProps> = ({ plan }) => {
  return (
    <>
      {plansTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{plan[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default PlanTableItem;
