import React from 'react';
import UserItem from './ResourceModuleItem';
import List from 'components/list/List';
import { Grid } from '@mui/material';
import { Resource } from 'types/resource';

type ResourceModuleListProps = {
  resources: Resource[];
};

const ResourceModuleList: React.FC<ResourceModuleListProps> = ({ resources }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {resources.map(resource => (
            <UserItem key={resource.id} resource={resource} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ResourceModuleList;
