import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Delete } from '@mui/icons-material';

interface InvoiceActionsProps {
  handleValidation(): void;
  isPaid: boolean;
  handleDelete(): void;
}

const InvoiceActions: React.FC<InvoiceActionsProps> = ({ handleValidation, isPaid, handleDelete }) => {
  return (
    <div>
      <Tooltip title="Excluir" onClick={handleDelete}>
        <IconButton disabled={isPaid} color="inherit">
          <Delete />
        </IconButton>
      </Tooltip>
      <Tooltip title="Salvar" onClick={handleValidation}>
        <IconButton disabled={isPaid} color="inherit">
          <CheckIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default InvoiceActions;
