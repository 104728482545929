import React from 'react';
import { Switch, Typography } from '@mui/material';
import { Resource } from 'types/resource';
import PlanFormResources from './PlanFormResources';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
    padding: '20px 0',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rules: {
    marginTop: 0,
    maxWidth: 650,
  },
  title: {
    textTransform: 'uppercase',
  },
});

type PlanResourcesProps = {
  resources: Resource[];
  handleResourceChange(resourceId: number): void;
};

const PlanResources: React.FC<PlanResourcesProps> = ({ resources, handleResourceChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.rules}>
      {resources.map(
        resource =>
          !resource.resource_id && (
            <div key={resource.id} className={classes.formControl}>
              <div className={classes.header}>
                <Typography className={classes.title}>{resource.title}</Typography>
                <Switch color="primary" onChange={() => handleResourceChange(resource.id)} checked={resource.enabled} />
              </div>
              <Typography variant="body2" color="textSecondary">
                {resource.description}
              </Typography>
              {resource.resources.length > 0 && (
                <PlanFormResources resources={resource.resources} handleResourceChange={handleResourceChange} />
              )}
            </div>
          ),
      )}
    </div>
  );
};

export default PlanResources;
