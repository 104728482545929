import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, styled, Typography } from '@mui/material';

const useStyles = makeStyles({
  backButton: {
    marginRight: 15,
  },
});

interface HeaderStyleProps {
  gutterBottom: boolean;
}

const Header = styled('div', { shouldForwardProp: prop => prop !== 'gutterBottom' })<HeaderStyleProps>(props => ({
  marginBottom: props.gutterBottom ? 10 : 0,
  display: 'flex',
  alignItems: 'center',
  '@media print': {
    display: 'none',
  },
}));

interface PageHeaderProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  backUrl?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description, gutterBottom = true, backUrl }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Header gutterBottom={gutterBottom}>
      {backUrl && (
        <IconButton className={classes.backButton} size="small" onClick={() => navigate(backUrl)}>
          <ArrowBack />
        </IconButton>
      )}
      <div>
        <Typography variant="h6">{title}</Typography>
        {description && (
          <Typography color="textSecondary" variant="body2">
            {description}
          </Typography>
        )}
      </div>
    </Header>
  );
};

export default PageHeader;
