import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import PhoneInput from 'components/masked-input/__PhoneInput';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import React, { useEffect } from 'react';
import { addPhone, deletePhone, editPhone } from 'store/apiContext/modules/restaurant/actions';

const RestaurantPhones: React.FC = () => {
  const { dispatch, restaurant } = useRestaurant();

  useEffect(() => {
    if (restaurant.phones.length === 0) dispatch(addPhone({ id: 1, phone: '' }));
  }, [restaurant.phones, dispatch]);

  function handlePhoneChange(index: number, value: string) {
    dispatch(editPhone(index, value));
  }

  function handleDeletePhone(phoneId: number) {
    dispatch(deletePhone(phoneId));
  }

  return (
    <Grid item xs={12} lg={4} xl={4} md={6}>
      {restaurant.phones.map((phone, index) => (
        <TextField
          key={phone.id}
          label="Telefone"
          placeholder="Digite o telefone"
          margin="normal"
          value={phone.phone}
          onChange={e => handlePhoneChange(index, e.target.value)}
          fullWidth
          autoFocus
          InputProps={{
            inputComponent: PhoneInput as any,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => handleDeletePhone(phone.id)}>
                  <Delete />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ))}
    </Grid>
  );
};

export default RestaurantPhones;
