import { Dispatch, SetStateAction, useState } from 'react';
import { Plan } from 'types/plan';
import * as yup from 'yup';

export type PlanValidation = {
  name?: string;
  description?: string;
  price?: string;
};

type UsePlanValidation = [PlanValidation, Dispatch<SetStateAction<PlanValidation>>, (plan: Plan) => Promise<void>];

export function usePlanValidation(): UsePlanValidation {
  async function handleValidation(plan: Plan) {
    const schema = yup.object().shape({
      name: yup.string().required('O nome é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      price: yup.number().typeError('Informe um número válido').required('O preço é obrigatório'),
    });

    try {
      await schema.validate(plan);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }
      throw err;
    }
  }

  const [validation, setValidation] = useState<PlanValidation>({});
  return [validation, setValidation, handleValidation];
}
