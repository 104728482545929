import { Restaurant, RestaurantAddress, RestaurantPhone } from 'types/restaurant';
import { RestaurantActionsType } from './type';

export function setRestaurant(restaurant: Restaurant): RestaurantActionsType {
  return {
    type: 'SET_RESTAURANT',
    restaurant,
  };
}

export function restaurantChange(index: keyof Restaurant, value): RestaurantActionsType {
  return {
    type: 'RESTAURANT_CHANGE',
    index,
    value,
  };
}

export function addAddress(address: RestaurantAddress): RestaurantActionsType {
  return {
    type: 'ADD_ADDRESS',
    address,
  };
}

export function editAddress(address: RestaurantAddress): RestaurantActionsType {
  return {
    type: 'EDIT_ADDRESS',
    address,
  };
}

export function deleteAddress(addressId: number): RestaurantActionsType {
  return {
    type: 'DELETE_ADDRESS',
    addressId,
  };
}

export function deletePhone(phoneId: number): RestaurantActionsType {
  return {
    type: 'DELETE_PHONE',
    phoneId,
  };
}

export function addPhone(phone: RestaurantPhone): RestaurantActionsType {
  return {
    type: 'ADD_PHONE',
    phone,
  };
}

export function editPhone(index: number, value): RestaurantActionsType {
  return {
    type: 'EDIT_PHONE',
    index,
    value,
  };
}

export function selectImage(): RestaurantActionsType {
  return {
    type: 'IMAGE_SELECT',
  };
}

export function deleteImage(): RestaurantActionsType {
  return {
    type: 'IMAGE_DELETE',
  };
}
