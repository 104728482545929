import React, { useEffect, useMemo, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import { Grid } from '@mui/material';
import { Image } from 'types/image';
import { useDefaultImagesValidation } from './validation/defaultImagesValidation';
import { api } from 'services/api';
import { useMessaging } from 'providers/messaging';
import { DefaultImage, DefaultImageKeys } from 'types/defaultImage';
import DefaultImagesActions from './DefaultImagesActions';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import ImageUpload from 'components/image-upload/DefaultImageUpdate';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  logoContainer: {
    height: '300px!important',
  },
});

const DefaultImages: React.FC = () => {
  const classes = styles();

  const [defaultImages, setDefaultImages] = useState<DefaultImage[]>([
    {
      key: 'restaurant_cover',
      image_id: 0,
      image: null,
    },
    { key: 'restaurant_logo', image: null, image_id: 0 },
  ]);
  const [validation, setValidation, validate] = useDefaultImagesValidation();
  const messaging = useMessaging();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const restaurantLogo = useMemo(() => {
    const defaultImage = defaultImages.find(defaultImage => defaultImage.key === 'restaurant_logo');
    if (!defaultImage?.image) return null;

    return defaultImage;
  }, [defaultImages]);

  const restaurantCover = useMemo(() => {
    const defaultImage = defaultImages.find(defaultImage => defaultImage.key === 'restaurant_cover');
    if (!defaultImage?.image) return null;

    return defaultImage;
  }, [defaultImages]);

  useEffect(() => {
    api
      .get('/default-images')
      .then(response => {
        if (response.data.length > 0) setDefaultImages(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  function handleChange(index: DefaultImageKeys, value: Image | null) {
    setDefaultImages(defaultImages =>
      defaultImages.map(defaultImage => {
        defaultImage.image = defaultImage.key === index ? value : defaultImage.image;
        return defaultImage;
      }),
    );
  }

  function handleValidate() {
    setValidation({});

    validate(defaultImages)
      .then(() => handleSubmit())
      .catch(() => {
        messaging.handleOpen('Você precisa inserir todas as imagens');
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/default-images', defaultImages)
      .then(() => {
        messaging.handleOpen('Salvo');
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar');
      })
      .finally(() => setSaving(false));
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar title="Imagens padrão" ActionsComponent={<DefaultImagesActions handleSubmit={handleValidate} />} />
      <PageHeader title="Imagens padrão" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <Grid item xs={12} xl={4} lg={4} md={6}>
            <ImageUpload
              label="Logo do restaurante"
              handleSetImage={image => handleChange('restaurant_logo', image)}
              handleRemoveImage={() => handleChange('restaurant_logo', null)}
              image={restaurantLogo}
              validationError={validation.restaurant_logo}
              containerClassname={classes.logoContainer}
            />

            <ImageUpload
              label="Cover do restaurante"
              handleSetImage={image => handleChange('restaurant_cover', image)}
              handleRemoveImage={() => handleChange('restaurant_cover', null)}
              image={restaurantCover}
              validationError={validation.restaurant_cover}
              containerClassname={classes.logoContainer}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DefaultImages;
