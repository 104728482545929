import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

type ResourceNewActionsProps = {
  handleSubmit(): void;
};

const ResourceNewActions: React.FC<ResourceNewActionsProps> = ({ handleSubmit }) => {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ResourceNewActions;
