import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant="h4" align="center" className={classes.title} gutterBottom>
            404 Página não encontrada.
          </Typography>
          <Typography color="primary">
            <Link className={classes.link} to="/">
              Voltar ao início
            </Link>
          </Typography>
        </div>
      </Grid>
    </>
  );
}
