import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, Typography } from '@mui/material';
import { restaurantsTableTemplate } from '../../restaurantsTableTemplate';
import { Restaurant } from 'types/restaurant';
import { MoreHoriz } from '@mui/icons-material';
import { useRestaurants } from 'pages/restaurants/hook/useRestaurants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type RestaurantTableItemProps = {
  restaurant: Restaurant;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const RestaurantTableItem: React.FC<RestaurantTableItemProps> = ({ restaurant, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedRestaurant } = useRestaurants();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedRestaurant(restaurant);
  }

  return (
    <>
      {restaurantsTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{restaurant[item.id]}</Typography>
          </div>
        ),
      )}
    </>
  );
};

export default RestaurantTableItem;
