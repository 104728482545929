import React, { MouseEvent, useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import StarIcon from '@mui/icons-material/Star';
import MoreIcon from '@mui/icons-material/MoreVert';
import { RestaurantAddress } from 'types/restaurant';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import { deleteAddress } from 'store/apiContext/modules/restaurant/actions';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    marginTop: 15,
  },
  card: {
    position: 'relative',
    margin: '0 10px 0 0',
    minHeight: 305,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 10px',
      minHeight: 200,
    },
  },
  noAddress: {
    height: 200,
    padding: 20,
  },
  button: {
    marginTop: 20,
    display: 'flex',
  },
  star: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  btnMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  title: {
    marginBottom: 12,
  },
  address: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
}));

const RestaurantAddresses: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { dispatch, restaurant, setDialogNew, setDialogUpdate, setSelectedAddress } = useRestaurant();

  function handleClickIsMainClick() {
    setAnchorEl(null);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>, address: RestaurantAddress) {
    setAnchorEl(event.currentTarget);
    setSelectedAddress(address);
  }

  function handleAddressEditClick(address: RestaurantAddress) {
    setSelectedAddress(address);
    setDialogUpdate(true);
  }

  function handleAddressDeleteClick(addressId: number) {
    dispatch(deleteAddress(addressId));
  }

  return (
    <Grid container className={classes.container}>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleClickIsMainClick}>Marcar como principal</MenuItem>
      </Menu>
      {restaurant.addresses.length > 0 ? (
        restaurant.addresses.map((item, index) => {
          return (
            <Grid item xs={12} xl={2} lg={3} md={6} key={index}>
              <Card className={classes.card}>
                <CardContent>
                  {item.is_main && (
                    <Tooltip title="Endereço principal">
                      <StarIcon color="primary" className={classes.star} />
                    </Tooltip>
                  )}
                  <Typography className={classes.title} gutterBottom variant="caption" display="block" color="primary">
                    Endereço
                  </Typography>
                  <Typography variant="body2">{item.nickname}</Typography>
                  <Typography className={classes.address} variant="h6">
                    {item.address}
                  </Typography>
                  <Typography color={'textSecondary'}>
                    {item.city}/{item.region}
                  </Typography>
                  <Typography color={'textSecondary'}>{item.district}</Typography>
                  <Typography gutterBottom color={'textSecondary'}>
                    {item.postal_code}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleAddressEditClick(item)} size="small" color="primary" variant="text">
                    Alterar
                  </Button>
                  <Button onClick={() => handleAddressDeleteClick(item.id)} size="small" color="primary" variant="text">
                    Excluir
                  </Button>
                </CardActions>
                <div className={classes.btnMore}>
                  {!item.is_main && (
                    <IconButton onClick={event => handleMoreClick(event, item)}>
                      <MoreIcon />
                    </IconButton>
                  )}
                </div>
              </Card>
            </Grid>
          );
        })
      ) : (
        <Grid container direction="column" className={classes.noAddress}>
          <Typography variant="h6">Nenhum endereço foi cadastro.</Typography>
          <div className={classes.button}>
            <Button color="primary" size="small" onClick={() => setDialogNew(true)} variant="contained">
              Incluir endereço
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default RestaurantAddresses;
