import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { Restaurant } from 'types/restaurant';
import { makeStyles } from '@mui/styles';

type RestaurantModuleItemProps = {
  restaurant: Restaurant;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff!important',
    border: '1px solid #eee',
    borderRadius: 4,
    position: 'relative',
    alignItems: 'center',
    minHeight: 120,
    justifyContent: 'space-between!important',
  },
  productData: {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    overflow: 'hidden',
    height: 100,
    width: 100,
    flexShrink: 0,
  },
  img: {
    width: '100%',
    borderRadius: 4,
  },
});

const ListItemStyled = styled(ListItemButton)(() => ({
  display: 'flex',
  backgroundColor: '#fff',
  border: '1px solid #eee',
  borderRadius: 4,
  position: 'relative',
  alignItems: 'center',
  minHeight: 120,
  justifyContent: 'space-between',
}));

const RestaurantModuleItem: React.FC<RestaurantModuleItemProps> = ({ restaurant }) => {
  const classes = useStyles();

  return (
    <ListItemStyled
      key={restaurant.id}
      className={classes.listItem}
      onClick={() => history.push(`/restaurant/${restaurant.id}`)}
    >
      <div className={classes.productData}>
        <Typography variant="caption" color="primary">
          Restaurante {restaurant.id}
        </Typography>
        <Typography variant="h6">{restaurant.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {restaurant.description}
        </Typography>
      </div>
      {restaurant.image && (
        <div className={classes.imgWrapper}>
          <img className={classes.img} src={restaurant.image.imageUrl} alt={restaurant.name} />
        </div>
      )}
    </ListItemStyled>
  );
};

export default RestaurantModuleItem;
