import React from 'react';
import { User } from 'types/user';
import { Typography, Theme, ListItemButton, styled } from '@mui/material';
import history from 'services/history';
import { Image } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

type UserItemModuleProps = {
  user: User;
};

const useStyles = makeStyles<Theme>(theme => ({
  imageWrapper: {
    display: 'flex',
    height: 70,
    width: 70,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  img: {
    width: '100%',
  },
}));

const ListItemStyled = styled(ListItemButton)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
  '& > .data': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UserItemModule: React.FC<UserItemModuleProps> = ({ user }) => {
  const classes = useStyles();

  function handleClick(id?: number) {
    history.push(`/user/${id}`);
  }

  return (
    <ListItemStyled onClick={() => handleClick(user.id)} style={!user.active ? { opacity: 0.7 } : undefined}>
      <div className="data">
        <Typography gutterBottom color="primary" variant="caption">
          ID {user.id} - {user.active ? 'Ativo' : 'Inativo'}
        </Typography>
        <Typography variant="h6">{user.name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {user.username}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {user.email}
        </Typography>
      </div>
      {user.image && (
        <div className={classes.imageWrapper}>
          {user.image ? (
            <img src={user.image.imageUrl} alt={user.name} className={classes.img} />
          ) : (
            <Image color="secondary" />
          )}
        </div>
      )}
    </ListItemStyled>
  );
};

export default UserItemModule;
