import { Grid, MenuItem, TextField } from '@mui/material';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import React from 'react';

const RestaurantPlan: React.FC = () => {
  const { plans, handleChange, restaurant } = useRestaurant();
  return (
    <Grid container>
      <Grid item xs={12} xl={4} lg={4} md={6}>
        <TextField
          select
          label="Plano"
          fullWidth
          value={restaurant.plan_id}
          onChange={e => handleChange('plan_id', e.target.value)}
          autoFocus
          margin="normal"
        >
          {plans.map(plan => (
            <MenuItem value={plan.id} key={plan.id}>
              {plan.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default RestaurantPlan;
