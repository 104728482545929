import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { useRestaurantReducer } from 'store/apiContext/modules/restaurant/reducer';
import { restaurantChange, selectImage, setRestaurant, deleteImage } from 'store/apiContext/modules/restaurant/actions';
import RestaurantForm from '../RestaurantForm';
import Appbar from 'components/appbar/Appbar';
import RestaurantAction from './RestaurantActions';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import { Grid } from '@mui/material';
import RestaurantTabs from '../RestaurantTabs';
import { makeStyles } from '@mui/styles';
import RestaurantCustomization from '../customization/RestaurantCustomization';
import { api } from 'services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessaging } from 'providers/messaging';
import { Restaurant, RestaurantAddress } from 'types/restaurant';
import { useRestaurantValidation } from '../validation/restaurantValidation';
import RestaurantPhones from '../phones/RestaurantPhones';
import { RestaurantContextProvider } from 'pages/restaurants/hook/useRestaurant';
import RestaurantAddresses from '../addresses/RestaurantAddresses';
import RestaurantAddressNew from '../addresses/registration/new/RestaurantAddressNew';
import RestaurantAddressUpdate from '../addresses/registration/update/RestaurantAddressUpdate';
import { Plan } from 'types/plan';
import RestaurantPlan from '../plan/RestaurantPlan';

const useStyles = makeStyles({
  tabContent: {
    flex: 1,
  },
});

const RestaurantUpdate: React.FC = () => {
  const classes = useStyles();
  const [validation, setValidation, validate] = useRestaurantValidation();
  const [restaurant, dispatch] = useRestaurantReducer();
  const [tab, setTab] = useState(0);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const { handleOpen } = useMessaging();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [dialogNew, setDialogNew] = useState(false);
  const [dialogUpdate, setDialogUpdate] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<RestaurantAddress | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);

  const handleChange = useCallback(
    (index: keyof Restaurant, value: any) => {
      dispatch(restaurantChange(index, value));
    },
    [dispatch],
  );

  useEffect(() => {
    function loadRestaurant() {
      return api.get(`/restaurants/${id}`).then(response => {
        dispatch(setRestaurant(response.data));
      });
    }

    function loadPlans() {
      return api.get<Plan[]>('/plans').then(response => {
        setPlans(response.data);
      });
    }

    Promise.all([loadRestaurant(), loadPlans()]).finally(() => {
      setLoading(false);
    });
  }, [id, dispatch, handleChange]);

  useEffect(() => {
    const [key] = Object.keys(validation);

    const tab1 = ['addresses'];
    const tab2 = ['phones'];
    const tab3 = ['image', 'primary_color', 'secondary_color'];

    if (tab1.includes(key)) setTab(1);
    else if (tab2.includes(key)) setTab(2);
    else if (tab3.includes(key)) setTab(3);
    else setTab(0);

    if (key === 'addresses') handleOpen('Informe um endereço');

    if (key === 'image') handleOpen('Informe a logo do restaurante');
  }, [validation, handleOpen]);

  function handleTabChange(value) {
    setTab(value);
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put(`/restaurants/${id}`, restaurant)
      .then(() => {
        handleOpen('Salvo');
        navigate('/restaurants');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleValidation(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    validate(restaurant)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        console.log(err);
      });
  }

  function handleImageSelect() {
    dispatch(selectImage());
  }

  function handleDeleteImage() {
    dispatch(deleteImage());
  }

  return (
    <RestaurantContextProvider
      value={{
        dispatch,
        restaurant,
        setDialogNew: state => setDialogNew(state),
        setDialogUpdate: state => setDialogUpdate(state),
        selectedAddress,
        setSelectedAddress: address => setSelectedAddress(address),
        handleValidation,
        validation,
        handleChange,
        plans,
      }}
    >
      {dialogNew && <RestaurantAddressNew onExited={() => setDialogNew(false)} />}
      {dialogUpdate && <RestaurantAddressUpdate onExited={() => setDialogUpdate(false)} />}
      <Appbar
        title={restaurant.imageSelected ? 'Foto' : 'Editar Restaurante'}
        backAction={restaurant.imageSelected ? handleImageSelect : undefined}
        Tab={<RestaurantTabs tab={tab} handleTabChange={handleTabChange} />}
        ActionsComponent={
          <RestaurantAction
            handleValidation={handleValidation}
            tab={tab}
            handleDeleteImage={handleDeleteImage}
            imageSelected={restaurant.imageSelected}
          />
        }
      />
      {saving && <Loading />}
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container>
          <div className={classes.tabContent}>
            {tab === 0 && (
              <RestaurantForm
                restaurant={restaurant}
                handleChange={handleChange}
                validation={validation}
                handleValidation={handleValidation}
              />
            )}
            {tab === 1 && <RestaurantAddresses />}
            {tab === 2 && <RestaurantPhones />}
            {tab === 3 && <RestaurantCustomization />}
            {tab === 4 && <RestaurantPlan />}
          </div>
        </Grid>
      )}
    </RestaurantContextProvider>
  );
};

export default RestaurantUpdate;
