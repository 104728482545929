import { Dispatch, SetStateAction, useState } from 'react';
import { DefaultImage } from 'types/defaultImage';
import * as yup from 'yup';

export type DefaultImagesValidation = {
  restaurant_logo?: string;
  restaurant_cover?: string;
};

type UseDefaultImagesValidation = [
  DefaultImagesValidation,
  Dispatch<SetStateAction<DefaultImagesValidation>>,
  (defaultImages: DefaultImage[]) => Promise<void>,
];

export function useDefaultImagesValidation(): UseDefaultImagesValidation {
  async function handleValidation(defaultImages: DefaultImage[]) {
    const schema = yup
      .array()
      .min(2)
      .of(
        yup.object().shape({
          key: yup.string().required('O logo é obrigatório'),
          image: yup.object().required('O banner é obrigatório'),
        }),
      );

    try {
      await schema.validate(defaultImages);
    } catch (err) {
      const error = err as yup.ValidationError;

      setValidation({
        [error.path as string]: error.message,
      });

      throw err;
    }
  }

  const [validation, setValidation] = useState<DefaultImagesValidation>({});
  return [validation, setValidation, handleValidation];
}
