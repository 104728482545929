import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useInvoices } from '../hooks/useInvoices';
import { api } from 'services/api';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type InvoiceListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const InvoiceListMenu: React.FC<InvoiceListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedInvoice, handleUpdateList, handleDelete, handleDeleteFromList } = useInvoices();

  function handleClick() {
    setAnchorEl(null);

    if (!selectedInvoice) {
      return;
    }

    const invoice = {
      ...selectedInvoice,
      paid_at: new Date(),
      status: 'paid' as any,
      formattedPaidAt: format(new Date(), 'P', { locale: ptBR }),
    };

    api
      .put(`/invoices/${selectedInvoice.id}`, invoice)
      .then(() => {
        handleUpdateList(invoice);
      })
      .catch(err => console.error(err));
  }

  function handleDeleteClick() {
    if (!selectedInvoice) {
      return;
    }

    setAnchorEl(null);
    handleDelete().then(() => handleDeleteFromList(selectedInvoice));
  }

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem disabled={!!selectedInvoice?.paid_at} onClick={handleClick}>
        Marcar como pago
      </MenuItem>
      <MenuItem onClick={handleDeleteClick} disabled={!!selectedInvoice?.paid_at}>
        Excluir
      </MenuItem>
    </Menu>
  );
};

export default InvoiceListMenu;
