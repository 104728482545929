import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

type PlanUpdateActionsProps = {
  handleSubmit(): void;
  handleDelete(): void;
};

const PlanUpdateActions: React.FC<PlanUpdateActionsProps> = ({ handleSubmit, handleDelete }) => {
  return (
    <>
      <Tooltip title="Excluir">
        <IconButton color="inherit" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Salvar">
        <IconButton color="inherit" onClick={handleSubmit}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default PlanUpdateActions;
