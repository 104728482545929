import React from 'react';
import { IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SidebarItem from '../SidebarItem';
import { SIDEBAR_WIDTH } from 'constants/constants';
import SidebarBackAction from '../SidebarBackAction';
import { ImageOutlined } from '@mui/icons-material';
import { useApp } from 'providers/app';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.dark,
    zIndex: 2,
    transform: `translateX(-${SIDEBAR_WIDTH}px)`,
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
}));

type SettingsSidebarProps = {
  show: boolean;
  handleClose(): void;
};

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({ show, handleClose }) => {
  const classes = useStyles();
  const app = useApp();

  return (
    <div className={classes.container} style={{ transform: show ? 'translateX(0)' : undefined }}>
      <div className={classes.drawerHeader}>
        <IconButton color="inherit" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color="inherit" variant="h6">
          configurações
        </Typography>
      </div>
      {!app.isMobile && app.windowWidth > 960 && (
        <SidebarBackAction handleClick={handleClose} icon={<ArrowBackIcon />} text="Voltar" />
      )}
      <SidebarItem to="/config/default-images" icon={<ImageOutlined />} text="imagens" />
    </div>
  );
};

export default SettingsSidebar;
