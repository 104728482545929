import { styled, TextField } from '@mui/material';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import React, { useEffect, useRef } from 'react';
import { Invoice } from 'types/invoice';
import { InvoiceValidation } from './validation/invoiceValidation';

const Container = styled('div')({
  maxWidth: 400,
});

interface InvoiceFormProps {
  validation: InvoiceValidation;
  handleChange(index: keyof Invoice, value: any): void;
  invoice: Invoice;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({ validation, handleChange, invoice }) => {
  const inputs = {
    value: useRef<HTMLInputElement>(null),
    payday: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) {
      return;
    }

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Container>
      <TextField
        disabled
        label="Emitida em"
        fullWidth
        margin="normal"
        defaultValue={invoice.formattedCreatedAt}
        type="text"
      />
      <TextField
        disabled
        label="Restaurante"
        fullWidth
        margin="normal"
        defaultValue={invoice.restaurant?.name}
        type="text"
      />
      <TextField
        label="Valor (R$)"
        fullWidth
        margin="normal"
        value={invoice.value}
        onChange={event => handleChange('value', event.target.value)}
        autoFocus
        type="number"
        inputProps={{
          inputMode: 'decimal',
        }}
        disabled={!!invoice.paid_at}
      />
      <KeyboardDatePicker
        fullWidth
        margin="normal"
        label="Vence em"
        value={invoice.payday}
        onChange={date => handleChange('payday', date)}
        disabled={!!invoice.paid_at}
      />

      <button type="submit" style={{ display: 'none' }} />
    </Container>
  );
};

export default InvoiceForm;
