import { TextField } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialog/Dialog';
import PostalCodeInput from 'components/masked-input/PostalCodeInput';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { postalCodeSearch } from 'services/postalCodeSearch';
import { addAddress } from 'store/apiContext/modules/restaurant/actions';
import { RestaurantAddress } from 'types/restaurant';
import { useRestaurantAddressValidation } from '../validation/restaurantAddressValidation';
import RestaurantAddressNewActions from './RestaurantAddressNewActions';

let timer: NodeJS.Timeout;

type RestaurantAddressNewProps = {
  onExited(): void;
};

const RestaurantAddressNew: React.FC<RestaurantAddressNewProps> = ({ onExited }) => {
  const [address, setAddress] = useState<RestaurantAddress>({
    id: new Date().getTime(),
    address: '',
    number: '',
    complement: '',
    city: '',
    district: '',
    is_main: false,
    postal_code: '',
    region: '',
    active: true,
    nickname: '',
  });
  const { dispatch } = useRestaurant();
  const [postalCode, setPostalCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [validation, setValidation, validate] = useRestaurantAddressValidation();
  const [postalCodeValidation, setPostalCodeValidation] = useState({
    error: false,
    message: '',
    hasData: false,
  });

  const inputs = {
    postal_code: useRef<HTMLInputElement>(null),
    address: useRef<HTMLInputElement>(null),
    number: useRef<HTMLInputElement>(null),
    district: useRef<HTMLInputElement>(null),
    complement: useRef<HTMLInputElement>(null),
    region: useRef<HTMLInputElement>(null),
    city: useRef<HTMLInputElement>(null),
    nickname: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  useEffect(() => {
    if (!postalCodeValidation.error && postalCodeValidation.hasData) inputs.number.current?.focus();
  }, [postalCodeValidation]); //eslint-disable-line

  useEffect(() => {
    if (inputs.postal_code.current) inputs.postal_code.current.focus();
  }, [inputs.postal_code]);

  function handleValidation(handleClose: () => void, e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    if (postalCodeValidation.error) return;

    validate(address)
      .then(() => {
        handleSubmit(handleClose);
        setValidation({});
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleSubmit(handleClose: () => void) {
    dispatch(addAddress(address));
    handleClose();
  }

  function handleChange(index: keyof RestaurantAddress, value) {
    setAddress(address => ({
      ...address,
      [index]: value,
    }));
  }

  function handleChangeCep(value: string) {
    setPostalCode(value);
    setPostalCodeValidation({ error: false, message: '', hasData: false });

    const newPostalCode = value.replace(/\D/g, '');

    clearTimeout(timer);

    if (newPostalCode.length === 0) return false;

    if (newPostalCode.length < 8) {
      setPostalCodeValidation({
        error: true,
        message: 'CEP inválido',
        hasData: false,
      });
    }

    if (newPostalCode.length === 8)
      timer = setTimeout(() => {
        setLoading(true);
        postalCodeSearch(newPostalCode)
          .then(response => {
            if (response.data.erro) {
              setPostalCodeValidation({
                error: true,
                message: 'CEP inexistente',
                hasData: false,
              });
              return;
            }
            const { data } = response;
            setPostalCodeValidation({ error: false, message: '', hasData: true });
            setAddress(oldValue => ({
              ...oldValue,
              address: data.logradouro,
              number: '',
              complement: data.complemento,
              region: data.uf.toLocaleUpperCase(),
              city: data.localidade,
              district: data.bairro,
              postal_code: postalCode,
            }));
          })
          .catch(err => {
            setPostalCodeValidation({
              error: true,
              message: err.message,
              hasData: false,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
  }

  return (
    <Dialog
      onExited={onExited}
      maxWidth="sm"
      title="Adicionar endereço"
      ComponentActions={<RestaurantAddressNewActions handleSubmit={handleValidation} />}
    >
      <DialogConsumer>
        {({ handleClose }) => (
          <form onSubmit={e => handleValidation(handleClose, e)}>
            <TextField
              inputRef={inputs.nickname}
              error={!!validation.nickname}
              helperText={validation.nickname}
              placeholder="Apelido da loja"
              label="Apelido da loja"
              value={address.nickname}
              onChange={e => handleChange('nickname', e.target.value)}
              margin="normal"
              fullWidth
              autoFocus
            />
            <TextField
              inputRef={inputs.postal_code}
              value={postalCode}
              onChange={e => handleChangeCep(e.target.value)}
              placeholder="CEP"
              label="CEP"
              error={postalCodeValidation.error}
              helperText={loading ? 'Pesquisando...' : postalCodeValidation.message && postalCodeValidation.message}
              disabled={loading}
              fullWidth
              InputProps={{ inputComponent: PostalCodeInput as any }}
              autoFocus
            />
            {!postalCodeValidation.error && postalCodeValidation.hasData && (
              <>
                <TextField
                  inputRef={inputs.address}
                  error={!!validation.address}
                  helperText={validation.address}
                  placeholder="Endereço"
                  label="Endereço"
                  value={address.address}
                  onChange={e => handleChange('address', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  inputRef={inputs.number}
                  error={!!validation.number}
                  helperText={validation.number}
                  placeholder="Número"
                  label="Número"
                  value={address.number}
                  onChange={e => handleChange('number', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  inputRef={inputs.district}
                  error={!!validation.district}
                  helperText={validation.district}
                  placeholder="Bairro"
                  label="Bairro"
                  value={address.district}
                  onChange={e => handleChange('district', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  inputRef={inputs.complement}
                  placeholder="Complemento do endereço"
                  label="Complemento"
                  value={address.complement || ''}
                  onChange={e => handleChange('complement', e.target.value)}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  inputRef={inputs.city}
                  error={!!validation.city}
                  helperText={validation.city}
                  placeholder="Cidade"
                  label="Cidade"
                  value={address.city}
                  onChange={e => handleChange('city', e.target.value)}
                  margin="normal"
                  fullWidth
                  disabled
                />
                <TextField
                  inputRef={inputs.region}
                  error={!!validation.region}
                  helperText={validation.region}
                  placeholder="Estado"
                  label="Estado"
                  value={address.region}
                  onChange={e => handleChange('region', e.target.value)}
                  margin="normal"
                  fullWidth
                  disabled
                />
              </>
            )}
            <button type="submit" style={{ display: 'none' }} />
          </form>
        )}
      </DialogConsumer>
    </Dialog>
  );
};

export default RestaurantAddressNew;
