import { TextField } from '@mui/material';
import Dialog, { DialogConsumer } from 'components/dialog/Dialog';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { editAddress } from 'store/apiContext/modules/restaurant/actions';
import { RestaurantAddress } from 'types/restaurant';
import { useRestaurantAddressValidation } from '../validation/restaurantAddressValidation';
import RestaurantAddressUpdateActions from './RestaurantAddressUpdateActions';

type RestaurantAddressNew = {
  onExited(): void;
};

const RestaurantAddressUpdate: React.FC<RestaurantAddressNew> = ({ onExited }) => {
  const { dispatch, selectedAddress } = useRestaurant();
  const [address, setAddress] = useState<RestaurantAddress>(selectedAddress || ({} as RestaurantAddress));
  const [validation, setValidation, validate] = useRestaurantAddressValidation();

  const inputs = {
    postal_code: useRef<HTMLInputElement>(null),
    address: useRef<HTMLInputElement>(null),
    number: useRef<HTMLInputElement>(null),
    district: useRef<HTMLInputElement>(null),
    complement: useRef<HTMLInputElement>(null),
    region: useRef<HTMLInputElement>(null),
    city: useRef<HTMLInputElement>(null),
    nickname: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleValidation(handleClose: () => void, e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    validate(address)
      .then(() => {
        handleSubmit(handleClose);
        setValidation({});
      })
      .catch(err => {
        console.error(err);
      });
  }

  function handleSubmit(handleClose: () => void) {
    dispatch(editAddress(address));
    handleClose();
  }

  function handleChange(index: keyof RestaurantAddress, value) {
    setAddress(address => ({
      ...address,
      [index]: value,
    }));
  }

  return (
    <Dialog
      onExited={onExited}
      maxWidth="sm"
      title="Adicionar endereço"
      ComponentActions={<RestaurantAddressUpdateActions handleSubmit={handleValidation} />}
    >
      <DialogConsumer>
        {({ handleClose }) => (
          <form onSubmit={e => handleValidation(handleClose, e)}>
            <TextField
              inputRef={inputs.nickname}
              error={!!validation.nickname}
              helperText={validation.nickname}
              placeholder="Apelido da loja"
              label="Apelido da loja"
              value={address.nickname}
              onChange={e => handleChange('nickname', e.target.value)}
              margin="normal"
              fullWidth
              autoFocus
            />
            <TextField
              inputRef={inputs.address}
              error={!!validation.address}
              helperText={validation.address}
              placeholder="Endereço"
              label="Endereço"
              value={address.address}
              onChange={e => handleChange('address', e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              inputRef={inputs.number}
              error={!!validation.number}
              helperText={validation.number}
              placeholder="Número"
              label="Número"
              value={address.number}
              onChange={e => handleChange('number', e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              inputRef={inputs.district}
              error={!!validation.district}
              helperText={validation.district}
              placeholder="Bairro"
              label="Bairro"
              value={address.district}
              onChange={e => handleChange('district', e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              inputRef={inputs.complement}
              placeholder="Complemento do endereço"
              label="Complemento"
              value={address.complement || ''}
              onChange={e => handleChange('complement', e.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              inputRef={inputs.city}
              error={!!validation.city}
              helperText={validation.city}
              placeholder="Cidade"
              label="Cidade"
              value={address.city}
              margin="normal"
              fullWidth
              disabled
            />
            <TextField
              inputRef={inputs.region}
              error={!!validation.region}
              helperText={validation.region}
              placeholder="Estado"
              label="Estado"
              value={address.region}
              margin="normal"
              fullWidth
              disabled
            />

            <button type="submit" style={{ display: 'none' }} />
          </form>
        )}
      </DialogConsumer>
    </Dialog>
  );
};

export default RestaurantAddressUpdate;
