import React from 'react';
import { Typography, styled, ListItemButton } from '@mui/material';
import history from 'services/history';
import { Plan } from 'types/plan';

type PlanModuleItemProps = {
  plan: Plan;
};

const ListItemStyled = styled(ListItemButton)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: '1px solid #f5f5f5',
  backgroundColor: '#fff',
}));

const PlanModuleItem: React.FC<PlanModuleItemProps> = ({ plan }) => {
  return (
    <ListItemStyled onClick={() => history.push(`/plan/${plan.id}`)}>
      <Typography variant="h6">{plan.name}</Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {plan.description}
      </Typography>
      <Typography variant="body2">{plan.formattedPrice}</Typography>
    </ListItemStyled>
  );
};

export default PlanModuleItem;
