import React, { useEffect, useRef } from 'react';
import { TextField, styled } from '@mui/material';
import { useUser } from '../hook/useUser';
import InputPhone from 'components/masked-input/PhoneInput';
import RestaurantAutocomplete from './auto-complete/RestaurantAutoComplete';

interface UserFormProps {
  mode: 'edit' | 'new';
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 400,
});

const UserForm: React.FC<UserFormProps> = ({ mode }) => {
  const { validation, user, handleChange } = useUser();

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
    passwordConfirmation: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Container>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={validation.name && validation.name}
        variant="standard"
        margin="normal"
        fullWidth
        label="Nome completo"
        placeholder="Seu nome completo"
        value={user.name}
        autoComplete="name"
        onChange={event => handleChange('name', event.target.value)}
        autoFocus
      />
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email && validation.email}
        variant="standard"
        margin="normal"
        fullWidth
        label="E-mail"
        placeholder="Seu e-mail"
        value={user.email}
        autoComplete="email"
        disabled={mode === 'edit'}
        onChange={event => handleChange('email', event.target.value)}
      />
      <TextField
        inputRef={inputs.phone}
        error={!!validation.phone}
        helperText={validation.phone && validation.phone}
        variant="standard"
        margin="normal"
        fullWidth
        label="Telefone"
        placeholder="Seu telefone"
        value={user.phone}
        onChange={event => handleChange('phone', event.target.value)}
        InputProps={{
          inputComponent: InputPhone as any,
        }}
        autoComplete="tel"
        InputLabelProps={{ shrink: true }}
      />

      <RestaurantAutocomplete
        onSelect={restaurant => handleChange('restaurant', restaurant)}
        label="Restaurante"
        placeholder="Selecione o restaurante"
        value={user.restaurant}
      />
    </Container>
  );
};

export default UserForm;
