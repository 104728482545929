import React from 'react';

import Dialog from 'components/dialog/Dialog';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import RestaurantCustomizationActions from './RestaurantCustomizationActions';
import ImageUpload from 'components/image-upload/ImageUpload';

type RestaurantCustomizationImageProps = {
  onExited(): void;
};

const RestaurantCustomizationImage: React.FC<RestaurantCustomizationImageProps> = ({ onExited }) => {
  const { validation, restaurant, handleChange } = useRestaurant();

  return (
    <Dialog
      onExited={onExited}
      maxWidth="sm"
      height="80"
      title="Logo do restaurante"
      ComponentActions={<RestaurantCustomizationActions />}
    >
      <ImageUpload
        label="Logo deve ser uma imagem de 512x512"
        handleSetImage={image => handleChange('image', image)}
        validationError={validation.image}
        handleRemoveImage={() => handleChange('image', null)}
        image={restaurant.image}
      />
    </Dialog>
  );
};

export default RestaurantCustomizationImage;
