import React, { useState } from 'react';
import { Theme, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import { FiBox, FiLayers, FiLogOut, FiUsers, FiBarChart2, FiSettings, FiCreditCard } from 'react-icons/fi';
import { RiStore2Line } from 'react-icons/ri';
import SidebarItem from './SidebarItem';
import { useAuth } from 'providers/auth';
import history from 'services/history';
import { useApp } from 'providers/app';
import { SIDEBAR_WIDTH } from 'constants/constants';
import SettingsSidebar from './settings/SettingsSidebar';
import SidebarItemMore from './SidebarItemMore';

const useStyles = makeStyles<Theme>(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    backgroundColor: `${theme.palette.secondary.dark}!important`,
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    textTransform: 'uppercase',
  },
  avatar: {
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  logout: {
    position: 'absolute',
    bottom: 0,
  },
  account: {
    minHeight: 90,
  },
  content: {
    transition: 'opacity 0.4s ease',
    opacity: 1,
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const app = useApp();
  const auth = useAuth();
  const [settings, setSettings] = useState(false);

  function handleLogout() {
    auth.logout().then(() => {
      history.push('/login');
      document.body.classList.remove('zoom-animation');
    });
  }

  return (
    <Drawer
      variant={app.isMobile || app.windowWidth < 1280 ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={app.isOpenedMenu}
      onClose={app.handleOpenMenu}
    >
      <SettingsSidebar show={settings} handleClose={() => setSettings(false)} />

      <div className={classes.content} style={settings ? { display: 'none' } : undefined}>
        <div className={classes.drawerHeader}>
          <Typography variant="h6">DELIVERY MASTER</Typography>
        </div>
        <SidebarItem to="/dashboard" icon={<FiBarChart2 size={22} />} text="dashboard" />
        <SidebarItem to="/restaurants" icon={<RiStore2Line size={22} />} text="restaurantes" />
        <SidebarItem to="/users" icon={<FiUsers size={22} />} text="usuários" />
        <SidebarItem to="/plans" icon={<FiLayers size={22} />} text="planos" />
        <SidebarItem to="/resources" icon={<FiBox size={22} />} text="recursos" />
        <SidebarItem to="/invoices" icon={<FiCreditCard size={22} />} text="faturas" />
        <SidebarItemMore icon={<FiSettings size={22} />} text="configurações" handleClick={() => setSettings(true)} />
        <SidebarItem icon={<FiLogOut size={22} />} text="sair" isLink={false} onClick={handleLogout} />
      </div>
    </Drawer>
  );
};

export default Sidebar;
