import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRestaurant } from 'pages/restaurants/hook/useRestaurant';
import { deleteImage } from 'store/apiContext/modules/restaurant/actions';

const RestaurantCustomizationActions: React.FC = () => {
  const { restaurant, dispatch } = useRestaurant();

  function handleClick() {
    dispatch(deleteImage());
  }

  return (
    <>
      {restaurant.imageSelected && (
        <Tooltip title="Excluir logo">
          <IconButton color="inherit" onClick={handleClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default RestaurantCustomizationActions;
