import React, { useState, useEffect, useRef } from 'react';
import RestaurantsActions from './RestaurantsActions';
import { api, getCancelTokenSource } from 'services/api';
import { Button, Theme } from '@mui/material';
import NoData from 'components/nodata/NoData';
import { Restaurant } from 'types/restaurant';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import PaginationProvider from 'providers/pagination';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'providers/app';
import useSearch from 'hooks/useSearch';
import useTableOrder from 'hooks/useTableOrder';
import { restaurantsTableTemplate } from './restaurantsTableTemplate';
import RestaurantTableList from './list/table/RestaurantTableList';
import RestaurantModuleList from './list/module/RestaurantModuleList';
import history from 'services/history';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RestaurantsProvider } from './hook/useRestaurants';
import { makeStyles } from '@mui/styles';
import TableContainer from 'components/table/TableContainer';
import RestaurantsFilterBox from './RestaurantsFilterBox';
import Loading from 'components/loading/Loading';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Restaurants: React.FC = () => {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const search = useSearch();
  const [filtered, setFiltered] = useState<Restaurant[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [indexToSearch, setIndexToSearch] = useState('name');
  const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | null>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    setLoading(true);
    api
      .get('/restaurants', { cancelToken: source.token })
      .then(response => {
        if (request)
          setRestaurants(
            response.data.map(restaurant => ({
              ...restaurant,
              formattedCreatedAt: format(parseISO(restaurant.created_at), 'PPp', { locale: ptBR }),
            })),
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    setFiltered(restaurants);
  }, [restaurants]);

  useEffect(() => {
    const p = search(searchValue, indexToSearch, restaurants);
    setFiltered(p);
  }, [indexToSearch, searchValue, search, restaurants]);

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [indexToSearch]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  function handleActivateRestaurant(): void {
    if (!selectedRestaurant) {
      return;
    }

    setSaving(true);

    api
      .put(`/restaurants/${selectedRestaurant.id}/activate`)
      .then(() => handleUpdateList({ ...selectedRestaurant, active: true }))
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleDeactivateRestaurant(): void {
    if (!selectedRestaurant) {
      return;
    }

    setSaving(true);

    api
      .put(`/restaurants/${selectedRestaurant.id}/deactivate`)
      .then(() => handleUpdateList({ ...selectedRestaurant, active: false }))
      .catch(err => console.error(err))
      .finally(() => setSaving(false));
  }

  function handleUpdateList(restaurant: Restaurant) {
    setRestaurants(state =>
      state.map(item => {
        if (item.id === restaurant.id) {
          return restaurant;
        }

        return item;
      }),
    );
  }

  return (
    <RestaurantsProvider
      value={{
        setSelectedRestaurant,
        selectedRestaurant,
        handleDeactivateRestaurant,
        handleActivateRestaurant,
        handleUpdateList,
      }}
    >
      <Appbar title="Restaurantes" ActionsComponent={<RestaurantsActions />} />

      <PageHeaderActions
        title="Restaurantes"
        description="Gestão dos restaurantes"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/restaurant')}>
            Adicionar
          </Button>
        }
      />

      {saving && <Loading />}

      <TableContainer tableTemplate={restaurantsTableTemplate}>
        <RestaurantsFilterBox
          displayMode={displayMode}
          indexToSearch={indexToSearch}
          searchValue={searchValue}
          setDisplayMode={setDisplayMode}
          setIndexToSearch={setIndexToSearch}
          setSearchValue={setSearchValue}
        />
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum restaurante para exibir" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <RestaurantTableList restaurants={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <RestaurantModuleList restaurants={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </RestaurantsProvider>
  );
};

export default Restaurants;
