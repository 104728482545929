import React, { FormEvent, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import { api } from 'services/api';
import { useMessaging } from 'providers/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import ResourceNewActions from './ResourceNewActions';
import AccessRuleForm from '../ResourceForm';
import PageHeader from 'components/page-header/PageHeader';
import { useResourceValidation } from '../validation/resourceValidation';
import { Resource } from 'types/resource';

const ResourceNew: React.FC = () => {
  const [resource, setResource] = useState<Resource>({} as Resource);
  const [validation, setValidation, validate] = useResourceValidation();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const [resources, setResources] = useState<Resource[]>([]);

  useEffect(() => {
    api
      .get('/resources')
      .then(response => {
        setResources(response.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleChange(index: keyof Resource, value: any) {
    setResource(oldValue => ({
      ...oldValue,
      [index]: value,
    }));
  }

  function handleValidation(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setValidation({});

    validate(resource)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path as string]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/resources`, resource)
      .then(() => {
        handleOpen('Salvo');
        setLoading(false);
        history.push('/resources');
      })
      .catch(err => {
        setSaving(false);
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o recurso');
      });
  }

  return (
    <>
      {saving && <Loading />}
      <Appbar
        backAction={() => history.push('/access-rules')}
        title="Regra de acesso"
        ActionsComponent={<ResourceNewActions handleSubmit={handleValidation} />}
      />
      <PageHeader title="Novo recurso" />
      {loading ? (
        <InsideLoading />
      ) : (
        <Grid container direction="column">
          <Grid item xs={12} lg={4} xl={4} md={6}>
            <form onSubmit={handleValidation}>
              <AccessRuleForm
                handleChange={handleChange}
                resource={resource}
                validation={validation}
                resources={resources}
              />
              <button type="submit" style={{ display: 'none' }} />
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ResourceNew;
