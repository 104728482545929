import { createContext, Dispatch, useContext } from 'react';
import { RestaurantActionsType } from 'store/apiContext/modules/restaurant/type';
import { Plan } from 'types/plan';
import { Restaurant, RestaurantAddress } from 'types/restaurant';
import { RestaurantValidation } from '../registration/validation/restaurantValidation';

type RestaurantContextValue = {
  dispatch: Dispatch<RestaurantActionsType>;
  restaurant: Restaurant;
  setDialogNew(state: boolean): void;
  setDialogUpdate(state: boolean): void;
  selectedAddress: null | RestaurantAddress;
  setSelectedAddress(address: RestaurantAddress): void;
  validation: RestaurantValidation;
  handleChange(index: keyof Restaurant, value: any);
  handleValidation(): void;
  plans: Plan[];
};

const RestaurantContext = createContext<RestaurantContextValue>({} as RestaurantContextValue);
export const RestaurantContextProvider = RestaurantContext.Provider;

export function useRestaurant(): RestaurantContextValue {
  const context = useContext(RestaurantContext);
  return context;
}
