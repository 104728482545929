import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { useRestaurantReducer } from 'store/apiContext/modules/restaurant/reducer';
import { restaurantChange } from 'store/apiContext/modules/restaurant/actions';
import RestaurantForm from '../RestaurantForm';
import Appbar from 'components/appbar/Appbar';
import RestaurantAction from './RestaurantActions';
import Loading from 'components/loading/Loading';
import { Grid } from '@mui/material';
import RestaurantTabs from '../RestaurantTabs';
import { makeStyles } from '@mui/styles';
import RestaurantCustomization from '../customization/RestaurantCustomization';
import { api } from 'services/api';
import { useNavigate } from 'react-router-dom';
import { useMessaging } from 'providers/messaging';
import { Restaurant, RestaurantAddress } from 'types/restaurant';
import { useRestaurantValidation } from '../validation/restaurantValidation';
import RestaurantPhones from '../phones/RestaurantPhones';
import { RestaurantContextProvider } from 'pages/restaurants/hook/useRestaurant';
import RestaurantAddresses from '../addresses/RestaurantAddresses';
import RestaurantAddressNew from '../addresses/registration/new/RestaurantAddressNew';
import RestaurantAddressUpdate from '../addresses/registration/update/RestaurantAddressUpdate';
import { Plan } from 'types/plan';
import RestaurantPlan from '../plan/RestaurantPlan';

const useStyles = makeStyles({
  tabContent: {
    flex: 1,
  },
});

const RestaurantNew: React.FC = () => {
  const classes = useStyles();
  const [validation, setValidation, validate] = useRestaurantValidation();
  const [restaurant, dispatch] = useRestaurantReducer();
  const [tab, setTab] = useState(0);
  const [saving, setSaving] = useState(false);
  const { handleOpen } = useMessaging();
  const navigate = useNavigate();
  const [dialogNew, setDialogNew] = useState(false);
  const [dialogUpdate, setDialogUpdate] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<RestaurantAddress | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const [key] = Object.keys(validation);

    const tab1 = ['addresses'];
    const tab2 = ['phones'];
    const tab3 = ['image', 'primary_color', 'secondary_color'];

    if (tab1.includes(key)) setTab(1);
    else if (tab2.includes(key)) setTab(2);
    else if (tab3.includes(key)) setTab(3);
    else setTab(0);

    if (key === 'addresses') handleOpen('Informe um endereço');

    if (key === 'image') handleOpen('Informe a logo do restaurante');
  }, [validation, handleOpen]);

  const handleChange = useCallback(
    (index: keyof Restaurant, value: any) => {
      dispatch(restaurantChange(index, value));
    },
    [dispatch],
  );

  useEffect(() => {
    api.get<Plan[]>('/plans').then(response => {
      setPlans(response.data);
      if (response.data.length > 0) handleChange('plan_id', response.data[0].id);
    });
  }, [handleChange]);

  function handleTabChange(value) {
    setTab(value);
  }

  function handleSubmit() {
    setSaving(true);
    api
      .post('/restaurants', restaurant)
      .then(() => {
        handleOpen('Salvo');
        navigate('/restaurants');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleValidation(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    validate(restaurant)
      .then(() => {
        handleSubmit();
        setValidation({});
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <RestaurantContextProvider
      value={{
        dispatch,
        restaurant,
        setDialogNew: state => setDialogNew(state),
        setDialogUpdate: state => setDialogUpdate(state),
        selectedAddress,
        setSelectedAddress: address => setSelectedAddress(address),
        handleValidation,
        validation,
        handleChange,
        plans,
      }}
    >
      {dialogNew && <RestaurantAddressNew onExited={() => setDialogNew(false)} />}
      {dialogUpdate && <RestaurantAddressUpdate onExited={() => setDialogUpdate(false)} />}
      <Appbar
        title={restaurant.imageSelected ? 'Foto' : 'Editar Restaurante'}
        backAction={() => navigate('/restaurants')}
        Tab={<RestaurantTabs tab={tab} handleTabChange={handleTabChange} />}
        ActionsComponent={<RestaurantAction handleSubmit={handleValidation} tab={tab} />}
      />
      {saving && <Loading />}
      <Grid container>
        <div className={classes.tabContent}>
          {tab === 0 && (
            <RestaurantForm
              restaurant={restaurant}
              handleChange={handleChange}
              validation={validation}
              handleValidation={handleValidation}
            />
          )}
          {tab === 1 && <RestaurantAddresses />}
          {tab === 2 && <RestaurantPhones />}
          {tab === 3 && <RestaurantCustomization />}
          {tab === 4 && <RestaurantPlan />}
        </div>
      </Grid>
    </RestaurantContextProvider>
  );
};

export default RestaurantNew;
