import React from 'react';
import { Typography } from '@mui/material';
import { resourcesTableTemplate } from '../../resourcesTableTemplate';
import { Resource } from 'types/resource';

type ResourceTableItemProps = {
  resource: Resource;
};

const ResourceTableItem: React.FC<ResourceTableItemProps> = ({ resource }) => {
  return (
    <>
      {resourcesTableTemplate.map(item => (
        <div key={item.id}>
          <Typography variant="body2">{resource[item.id]}</Typography>
        </div>
      ))}
    </>
  );
};

export default ResourceTableItem;
