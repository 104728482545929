import React, { useState, useEffect, useRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoginValidation } from './Login';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  icon: {
    cursor: 'pointer',
  },
});

type LoginFormProps = {
  email: string;
  password: string;
  setEmail(email: string): void;
  setPassword(password: string): void;
  validation: LoginValidation;
};

const LoginForm: React.FC<LoginFormProps> = ({ email, password, setEmail, setPassword, validation }) => {
  const classes = useStyles();
  const [passVisibility, setPassVisibility] = useState(true);
  const inputs = {
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handlePasswordVisibility() {
    setPassVisibility(!passVisibility);
  }

  return (
    <>
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="Nome de usuário"
        placeholder="Seu nome de usuário"
        margin="normal"
        onChange={e => setEmail(e.target.value)}
        value={email}
        autoComplete="username"
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <PersonIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputRef={inputs.password}
        error={!!validation.password}
        helperText={validation.password}
        label="Senha"
        placeholder="Sua senha"
        margin="normal"
        onChange={e => setPassword(e.target.value)}
        value={password}
        type={passVisibility ? 'password' : 'text'}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {passVisibility ? (
                <VisibilityIcon className={classes.icon} onClick={handlePasswordVisibility} color="primary" />
              ) : (
                <VisibilityOffIcon className={classes.icon} onClick={handlePasswordVisibility} color="primary" />
              )}
            </InputAdornment>
          ),
        }}
      />
      <button type="submit" style={{ display: 'none' }} />
    </>
  );
};

export default LoginForm;
