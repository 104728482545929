import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InputPhone: React.ForwardRefRenderFunction<HTMLInputElement, CustomProps> = (props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      ref={ref}
      mask="00 00000-0000"
      onAccept={value => onChange({ target: { name: props.name, value: value as string } })}
      definitions={{
        '#': /[1-9]/,
      }}
    />
  );
};

export default React.forwardRef(InputPhone);
