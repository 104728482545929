import React, { useCallback, useContext, useState, useEffect, ReactNode } from 'react';
import { api } from 'services/api';
import history from 'services/history';
import { User } from 'types/user';

interface AuthContextValue {
  login(email: string, password: string): Promise<boolean>;
  logout(): Promise<boolean>;
  checkAuth(): boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  loggingOff: boolean;
  user: User | null;
}

const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loggingOff, setLoggingOff] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('@delivery-master/token');

    if (!token) {
      return;
    }

    setIsLoading(true);

    api
      .get(`/me`)
      .then(response => {
        setUser(response.data);
        setIsAuthenticated(true);
      })
      .catch(err => {
        if (err.response) console.log(err.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const login = useCallback(async (email: string, password: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      api
        .post('/login', { email, password })
        .then(_response => {
          const response = _response.data;
          localStorage.setItem('@delivery-master/token', response.token);
          setUser(response.user);
          setIsAuthenticated(true);
          resolve(true);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 401) reject(err);
          } else reject(err);
        });
    });
  }, []);

  const logout = useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      setLoggingOff(true);
      api
        .post('/logout')
        .then(() => {
          localStorage.removeItem('@delivery-master/token');
          setIsAuthenticated(false);
          setUser(null);
          history.push('/login');
          setLoggingOff(false);
          resolve(true);
        })
        .catch(err => {
          setLoggingOff(false);
          reject(new Error(err));
        });
    });
  }, []);

  const checkAuth = useCallback((): boolean => {
    return !!localStorage.getItem('@delivery-master/token');
  }, []);

  return (
    <AuthContext.Provider value={{ login, logout, checkAuth, user, isAuthenticated, isLoading, loggingOff }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextValue {
  const context = useContext(AuthContext);
  return context;
}

export default AuthProvider;
