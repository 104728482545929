import { Button, Theme } from '@mui/material';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import Pagination from 'components/pagination/Pagination';
import { useApp } from 'providers/app';
import PaginationProvider from 'providers/pagination';
import useSearch from 'hooks/useSearch';
import useTableOrder from 'hooks/useTableOrder';
import React, { useEffect, useState } from 'react';
import { api } from 'services/api';
import history from 'services/history';
import { Resource } from 'types/resource';
import ResourcesActions from './ResourcesActions';
import ResourceModuleList from './list/module/ResourceModuleList';
import ResourceTableList from './list/table/ResourceTableList';
import NoData from 'components/nodata/NoData';
import { resourcesTableTemplate } from './resourcesTableTemplate';
import UsersFilterBox from 'pages/users/UsersFilterBox';
import TableContainer from 'components/table/TableContainer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Resources: React.FC = () => {
  const classes = useStyles();
  const [resources, setResources] = useState<Resource[]>([]);
  const [loading, setLoading] = useState(true);
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(
    app.isMobile || app.windowWidth < 930 ? 'module' : 'list',
  );
  const [filtered, setFiltered] = useState<Resource[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const [indexToSearch, setIndexToSearch] = useState('description');
  const search = useSearch();

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    setFiltered(resources);
  }, [resources]);

  useEffect(() => {
    setLoading(true);
    api
      .get<Resource[]>('/resources')
      .then(response => {
        setResources(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const p = search(searchValue, indexToSearch, resources);
    setFiltered(p);
  }, [indexToSearch, searchValue, search, resources]);

  function handleSort(index: string) {
    const f = sort(index, filtered);
    setFiltered(f);
  }

  return (
    <>
      <Appbar title="Recursos" ActionsComponent={<ResourcesActions />} />
      <PageHeaderActions
        title="Recursos"
        description="Gestão dos recursos do sistema"
        ActionComponent={
          <Button color="primary" variant="contained" size="small" onClick={() => history.push('/resource')}>
            Adicionar
          </Button>
        }
      />
      <TableContainer tableTemplate={resourcesTableTemplate}>
        <UsersFilterBox
          displayMode={displayMode}
          indexToSearch={indexToSearch}
          searchValue={searchValue}
          setDisplayMode={setDisplayMode}
          setIndexToSearch={setIndexToSearch}
          setSearchValue={setSearchValue}
        />
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum recurso para exibir" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ResourceTableList resources={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <ResourceModuleList resources={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </>
  );
};

export default Resources;
