import React from 'react';
import { FilterList } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

const Container = styled('div')(props => ({
  display: 'none',
  [props.theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

interface InvoicesActionsProps {
  openDialog(): void;
}

const InvoicesActions: React.FC<InvoicesActionsProps> = ({ openDialog }) => {
  return (
    <Container>
      <IconButton onClick={openDialog} color="inherit">
        <FilterList />
      </IconButton>
    </Container>
  );
};

export default InvoicesActions;
