import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Invoice } from 'types/invoice';
import ListItem from 'components/list-item/ListItem';
import { MoreVert } from '@mui/icons-material';
import { useInvoices } from 'pages/invoices/hooks/useInvoices';

interface StatusProps {
  status: 'paid' | 'open';
}

const Status = styled('span')<StatusProps>(props => ({
  backgroundColor: props.status === 'paid' ? '#8BC34A' : '#ffc107',
  color: '#fff',
  padding: 5,
  borderRadius: 0,
  position: 'absolute',
  top: 0,
  right: 0,
  fontSize: 12,
  width: 70,
  textAlign: 'center',
}));

const ActionsContent = styled('div')({
  position: 'absolute',
  bottom: 10,
  right: 10,
});

type InvoiceItemModuleProps = {
  invoice: Invoice;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const InvoiceItemModule: React.FC<InvoiceItemModuleProps> = ({ invoice, setAnchorEl }) => {
  const history = useNavigate();
  const { setSelectedInvoice } = useInvoices();

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedInvoice(invoice);
  }

  return (
    <ListItem onClick={() => history(`/invoices/${invoice.id}`)}>
      <Status status={invoice.paid_at ? 'paid' : 'open'}>{invoice.paid_at ? 'pago' : 'aberto'}</Status>
      <Typography variant="caption" gutterBottom color="primary">
        fatura {invoice.id}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {invoice.restaurant_name}
      </Typography>
      <Typography variant="body2">{invoice.formattedValue}</Typography>
      <Typography variant="body2" color="textSecondary">
        Vence em {invoice.formattedPayday}
      </Typography>

      <ActionsContent>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </ActionsContent>
    </ListItem>
  );
};

export default InvoiceItemModule;
